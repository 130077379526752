import React, { useEffect, useState, useRef } from 'react';
import { motion } from 'framer-motion';
import { Link, useNavigate } from 'react-router-dom';
import ResizeObserver from 'rc-resize-observer';
import { TfiMouse, TfiAngleDown } from 'react-icons/tfi';

import { getValueFromParameterMap } from 'cms/NBossCMS';
import { getWebsite } from 'utils/WebsiteUtils';

import ShopFrontendControl from 'services/ShopFrontendControl';

import useWindowDimensions from 'hooks/useWindowDimensions';

export default function Menu(props) {
    const [categoryTree, setCategoryTree] = useState(null);
    const [addtitionalMenu, setAdditionalMenu] = useState(null);

    const [openKey, setOpenKey] = useState(false);

    const [menuHeight, setMenuHeight] = useState(0);
    const [scroll, setScroll] = useState(0);
    const [menuOverflow, setMenuOverflow] = useState(false);

    const ref = useRef();

    const { height } = useWindowDimensions();

    useEffect(() => {

    }, []);

    useEffect(() => {
        ShopFrontendControl.getCategoryTree('termekkategoriak-neroli-luxus-parfumeria-magyar').then(result => setCategoryTree(result));

        const _additionalMenu = (getWebsite().globalContent && getValueFromParameterMap(getWebsite().globalContent, 'left-menu-additional-menu'));
        if (_additionalMenu)
            setAdditionalMenu(_additionalMenu);

        const onScroll = () => { setScroll(window.pageYOffset); };

        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    useEffect(() => {
        // console.log('-');
        // console.log('Available height: ' + getAvailableHeight());
        // console.log('Menu height: ' + menuHeight);
        // console.log('Scroll: ' + scroll);

        setMenuOverflow(menuHeight > getAvailableHeight());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [menuHeight, height, scroll]);

    const getAvailableHeight = () => {
        let y = 0;
        if (ref && ref.current) y = ref.current.getBoundingClientRect().y;
        return height - y;
    };

    return (
        <ResizeObserver onResize={({ width, height }) => { setMenuHeight(height) }}>

            <div ref={ref} className={'relative flex flex-col ' + (props.className || '')}>

                <div className={'relative flex flex-col justify-start gap-6'}>

                    {categoryTree && categoryTree.itemList.map((item) => (<MainItem key={item.key} data={item} openable onOpenChanged={(key) => setOpenKey(openKey === key ? null : key)} open={item.key === openKey} light={props.light} right={props.right} />))}

                    {(addtitionalMenu && categoryTree) && addtitionalMenu.itemList.map((item) => (<MainItem key={item.key} data={item} onOpenChanged={(key) => setOpenKey(null)} open={item.key === openKey} light={props.light} right={props.right} />))}

                </div>

                {props.scrollIndicator && <ScrollIndicator visible={menuOverflow} />}

            </div>

        </ResizeObserver>
    );
}

function ScrollIndicator(props) {
    return (
        <div className={'sticky bottom-0 left-0 w-full h-0 pointer-events-none ' + (props.visible ? 'opacity-100' : 'opacity-0')}>
            <div className='w-full h-full relative'>
                <div className='absolute bottom-0 left-0 w-full bg-gradient-to-t from-white via-white h-12 flex gap-2 items-end pb-3 justify-end'>
                    <TfiMouse className='animate-pulse text-zinc-500' />
                    <TfiAngleDown className='text-gold' />
                </div>
            </div>
        </div>
    );
}

function MainItem(props) {
    const [hover, setHover] = useState(false);

    const navigate = useNavigate();

    return (
        <div className={'flex flex-col gap-2 ' + (props.right ? 'justify-end items-end' : 'justify-start items-start')}>

            <button onClick={() => { props.onOpenChanged(props.data.key); if (!props.openable) navigate(props.data.fullPath) }}>
                <div className={'font-slab font-light uppercase text-lg transition ' + (props.right ? 'text-right ' : 'text-left ') + (props.open ? 'text-gold' : ((props.light ? 'text-white' : '') + ' hover:text-gold'))} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>{props.data.title}</div>
                <div className='w-full overflow-hidden'>
                    <motion.div
                        className='h-0.5 bg-gold'
                        initial={false}
                        animate={hover || props.open ? 'hover' : 'normal'}
                        transition={{ duration: 0.3 }}
                        variants={{ 'hover': { x: props.right ? '75%' : '-75%' }, 'normal': { x: props.right ? '-105%' : '105%' } }} />
                </div>
            </button>

            {(props.open && props.data.childList) && <div className='flex flex-col gap-2'>{props.data.childList.map((item) => (<SubItem key={item.key} data={item} light={props.light} right={props.right} />))}</div>}

        </div>
    )
}

function SubItem(props) {
    return (
        <div className={'flex transition-transform ' + (props.right ? 'justify-end' : 'justify-start')}>

            <Link to={props.data.fullPath}>
                <div className={'font-slab font-light text-base transition hover:text-gold ' + (props.right ? 'text-right ' : 'text-left ') + (props.light ? 'text-white' : '')}>{props.data.title}</div>
            </Link>

        </div>

    )
}