import nBossConnection from 'services/nBossConnection.js';

class CmsVoteControl {
    constructor() {
        this.connection = nBossConnection.connection;
        this.CONTROL = 'cmsVoteControl';
    }

    /**
     * @param {typeKey} typeKey
     * @param {contentKey} contentKey
     * @param {value} value
     */
    vote(typeKey, contentKey, value) {
        return new Promise((resolve, reject) => {
            this.connection.post(`/${this.CONTROL}/vote`, { typeKey, contentKey, value }).then(response => resolve(response.data)).catch(reject);
        });
    }

}

export default new CmsVoteControl();
