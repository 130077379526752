import { Link } from 'react-router-dom';
import { SlHome } from 'react-icons/sl';

import TextSkeleton from 'elements/TextSkeleton';

export default function Breadcrumb(props) {
    return (
        <div className={'w-full  overflow-hidden ' + (props.className || '')}>

            {props.data && <>

                <Link to='/' className='group'><SlHome className='inline opacity-50 text-xs mb-0.5 transition hover:opacity-100 hover:text-gold' /></Link>
                <Separator />

                {props.data.map((item, index) => (<Item key={item.id} data={item} last={props.data.length - 1 === index} />))}

            </>}

            {!props.data && <TextSkeleton className='w-full sm:w-[320px] h-[24px]' />}

        </div>
    );
}

function Item(props) {
    return (
        <>
            <Link to={props.data.url} className={'inline text-xs sm:text-sm hover:text-gold transition ' + (props.className || '')}>
                {props.data.name}
            </Link>
            {!props.last && <Separator />}
        </>
    );
}

function Separator(props) {
    return (<div className='inline opacity-50 pointer-events-none px-2'>/</div>);
}