
import { useState, useEffect } from 'react';
import { TfiPlus } from 'react-icons/tfi';
import Toast from 'elements/Toast';

import NavigationLister from 'components/NavigationLister';
import PageHeading from 'elements/PageHeading';

import { NBossCMSPage, getValueFromParameterMap } from 'cms/NBossCMS.js';
import { getTranslation } from 'utils/DictionaryUtils';
import { separateThousands } from 'utils/GenericUtils';

import ShopGiftCardControl from 'services/ShopGiftCardControl';

import { useShop } from 'providers/ShopProvider';

export default function GiftCardPage(props) {
    const [page, setPage] = useState(null);

    const [giftCardList, setGiftCardList] = useState(null);
    const [samplePackList, setSamplePackList] = useState(null);

    useEffect(() => {
        ShopGiftCardControl.getGiftCardList().then(result => { setGiftCardList(result); });
        ShopGiftCardControl.getSamplePackList().then(result => { setSamplePackList(result); });
    }, []);

    let _data = {
        title: getValueFromParameterMap(page, 'title'),
        content: getValueFromParameterMap(page, 'content'),
        giftCardTitle: getValueFromParameterMap(page, 'giftCardTitle'),
        giftCardContent: getValueFromParameterMap(page, 'giftCardContent'),
        giftCardProductTitle: getValueFromParameterMap(page, 'giftCardProductTitle'),
        samplePackTitle: getValueFromParameterMap(page, 'samplePackTitle'),
        samplePackDescription: getValueFromParameterMap(page, 'samplePackDescription'),
        samplePackMaleProductTitle: getValueFromParameterMap(page, 'samplePackMaleProductTitle'),
        samplePackFemaleProductTitle: getValueFromParameterMap(page, 'samplePackFemaleProductTitle'),
        samplePackUnit: getValueFromParameterMap(page, 'samplePackUnit'),
        sidenote: getValueFromParameterMap(page, 'sidenote'),
    }

    return (
        <NBossCMSPage pageprops={props} pageDataReady={page => { setPage(page) }} className='w-full flex flex-col site-main-gap'>

            <PageHeading
                loading={(_data.title && _data.content) ? false : true}
                title={_data.title}
                content={_data.content} />

            <Block title={_data.giftCardTitle} content={_data.giftCardContent}>

                {giftCardList && giftCardList.map((item, index) => <Product key={item.id} data={item} />)}

            </Block>

            <Block title={_data.samplePackTitle} content={_data.samplePackDescription}>

                {samplePackList && samplePackList.map((item, index) => <Product key={item.id} data={item} plus />)}

            </Block>

            <div className='text-sm leading-6' dangerouslySetInnerHTML={{ __html: _data.sidenote }} />

            <NavigationLister />

        </NBossCMSPage>
    );
};


function Block(props) {
    return (
        <div className='flex flex-col gap-4'>

            <div className='flex flex-col gap-4'>
                <h2 className='heading'>{props.title}</h2>
                <div className='leading-7' dangerouslySetInnerHTML={{ __html: props.content }} />
            </div>

            <div className='flex flex-col gap-6'>
                {props.children}
            </div>

        </div>
    );
}

function Product(props) {
    const [quantity, setQuantity] = useState(1);
    const [toast, setToast] = useState(null);

    const { addGiftCardToBasket } = useShop();

    const addToCart = () => {
        addGiftCardToBasket(props.data.key, quantity);
        setToast('<b>' + props.data.name + '</b> ' + getTranslation('added-to-cart'));
    };

    return (
        <div className='bg-gray-100 p-6 flex flex-col 2xl:flex-row 2xl:items-center justify-between gap-4'>

            <div className='text-gold font-cursive text-2xl leading-none'>{props.data.name}</div>
            {/* <div className='text-xl font-bold font-slab whitespace-nowrap'>{props.data.name}</div> */}

            <div className='flex flex-col lg:flex-row lg:items-center justify-between gap-6'>

                <div className='flex flex-row items-center gap-4'>
                    {props.plus && <TfiPlus />}
                    <input className='border text-center font-bold p-2' type='number' min='1' max='99' value={quantity} onChange={e => setQuantity(e.target.value)} />
                    <div className='font-slab text-sm'>{props.data.unit}</div>
                </div>

                <div className='flex flex-col lg:flex-row items-center gap-4'>
                    <div className='text-2xl font-bold font-slab whitespace-nowrap'>{separateThousands(quantity * props.data.price)} <span className='text-sm'>Ft</span></div>
                    <button className='w-full lg:w-auto button plain dark px-8' onClick={() => addToCart()}>{getTranslation('add-to-cart')}</button>
                </div>

            </div>

            <Toast message={toast} onHide={() => setToast(null)} />

        </div>
    );
}