import React, { useState, useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import Modal, { ModalButton, ModalHeader, ModalContent, ModalInput } from 'elements/modal/Modal';

import ShopCustomerControl from 'services/ShopCustomerControl';
import ShopCustomerLogin from 'services/ShopCustomerLogin';

import { getTranslation } from 'utils/DictionaryUtils.js';
import { getQueryVariable } from 'utils/GenericUtils';

import { useAuth } from 'providers/AuthProvider';

export default function ModalAuth(props) {
	const { handleLogin } = useAuth();
	const [searchParams, setSearchParams] = useSearchParams();

	let location = useLocation();

	// const [contentType, setContentType] = useState('LOGIN');
	const [feedback, setFeedback] = useState(false);

	const [userName, setUserName] = useState('');
	const [userNameError, setUserNameError] = useState(null);

	const [email, setEmail] = useState('');
	const [emailError, setEmailError] = useState(null);

	const [password, setPassword] = useState('');
	const [passwordError, setPasswordError] = useState(null);

	const [passwordAgain, setPasswordAgain] = useState('');
	const [passwordAgainError, setPasswordAgainError] = useState(null);

	useEffect(() => {
		setFeedback(false);

		setUserNameError(null);
		setEmailError(null);
		setPasswordError(null);
		setPasswordAgainError(null);
	}, [props.contentType]);

	useEffect(() => { setUserNameError(null); if (props.contentType === 'LOGIN') setPasswordError(null); }, [props.contentType, userName]);
	useEffect(() => { setEmailError(null); }, [email]);
	useEffect(() => { setPasswordError(null); if (props.contentType === 'LOGIN') setUserNameError(null); }, [props.contentType, password]);
	useEffect(() => { setPasswordAgainError(null); }, [passwordAgain]);

	const doRegistration = () => {
		ShopCustomerControl.save({ userName, email, password, passwordAgain, customerLevel: 'Account' }).then(result => {
			if (result.successful)
				setFeedback(true);
			else {
				if (result.properties && result.properties.userName) setUserNameError(result.properties.userName[0].message);
				if (result.properties && result.properties.email) setEmailError(result.properties.email[0].message);
				if (result.properties && result.properties.password) setPasswordError(result.properties.password[0].message);
				if (result.properties && result.properties.passwordAgain) setPasswordAgainError(result.properties.passwordAgain[0].message);
			}
		});
	};

	const doLogin = () => {
		ShopCustomerLogin.login(userName, password).then((result) => {
			if (result) {
				setFeedback(true);
				props.onClose();
				handleLogin();
			} else {
				setUserNameError(getTranslation('please-check-data'));
				setPasswordError(getTranslation('please-check-data'));
			}
		});
	};

	const doForgotPassword = () => {
		ShopCustomerControl.requestNewPassword(email).then((result) => {
			if (result) {
				setFeedback(true);
			} else {
				setEmailError(getTranslation('please-check-data'));
			}
		});
	};

	const doNewPassword = () => {
		if (location && location.search) {
			let id = getQueryVariable(location.search, 'id');
			let key = getQueryVariable(location.search, 'key');
			if (id && key)
				ShopCustomerControl.setNewPassword(parseInt(id), key, { password, passwordAgain }).then((result) => {
					if (result && result.successful) {
						if (searchParams)
							setSearchParams('');
						setFeedback(true);
					} else {
						showPasswordError();
					}
				});
			else
				showPasswordError();
		} else {
			showPasswordError();
		}
	};

	const showPasswordError = () => {
		setPasswordError(getTranslation('please-check-data'));
		setPasswordAgainError(getTranslation('please-check-data'));
	};

	return (
		<Modal {...props} className='max-w-md'>

			<ModalHeader {...props}>

				<div className='heading'>
					{props.contentType === 'LOGIN' && getTranslation('login')}
					{props.contentType === 'REGISTER' && getTranslation('registration')}
					{props.contentType === 'FORGOT_PASSWORD' && getTranslation('forgot-password')}
					{props.contentType === 'NEW_PASSWORD' && getTranslation('new-password')}
				</div>

			</ModalHeader>

			<ModalContent disableVerticalPadding>

				{/* {console.log(props.contentType + ' - ' + feedback)} */}

				<div className='text-white text-sm leading-6 font-light tracking-wide'>
					{props.contentType === 'LOGIN' && getTranslation('login-content')}
					{props.contentType === 'REGISTER' && (feedback ? getTranslation('registration-feedback-content') : getTranslation('registration-content'))}
					{props.contentType === 'FORGOT_PASSWORD' && (feedback ? getTranslation('forgot-password-feedback-content') : getTranslation('forgot-password-content'))}
					{props.contentType === 'NEW_PASSWORD' && (feedback ? getTranslation('new-password-feedback-content') : getTranslation('new-password-content'))}
				</div>

				{!feedback && <div className='flex flex-col gap-2 pt-6'>

					{(props.contentType === 'REGISTER' || props.contentType === 'LOGIN') && <ModalInput label={getTranslation('user-name')} onChange={(e) => setUserName(e.target.value)} value={userName} error={userNameError} />}
					{(props.contentType === 'REGISTER' || props.contentType === 'FORGOT_PASSWORD') && (<ModalInput label={getTranslation('email-address')} type='email' onChange={(e) => setEmail(e.target.value)} value={email} error={emailError} />)}
					{props.contentType !== 'FORGOT_PASSWORD' && (<ModalInput label={getTranslation('password')} type='password' onChange={(e) => setPassword(e.target.value)} value={password} error={passwordError} />)}
					{(props.contentType === 'REGISTER' || props.contentType === 'NEW_PASSWORD') && (<ModalInput label={getTranslation('password-again')} type='password' onChange={(e) => setPasswordAgain(e.target.value)} value={passwordAgain} error={passwordAgainError} />)}

				</div>}

			</ModalContent>

			<ModalContent>

				<div className='flex flex-col gap-4'>

					{!feedback && <ModalButton /*disabled={!buttonEnabled}*/ label={props.contentType === 'LOGIN' ? getTranslation('login') : props.contentType === 'REGISTER' ? getTranslation('registration') : getTranslation('send')} onClick={() => props.contentType === 'LOGIN' ? doLogin() : props.contentType === 'REGISTER' ? doRegistration() : (props.contentType === 'NEW_PASSWORD' ? doNewPassword() : doForgotPassword())} />}
					{(props.contentType !== 'FORGOT_PASSWORD' || (props.contentType === 'FORGOT_PASSWORD' || feedback)) && (<ModalButton label={props.contentType === 'LOGIN' ? getTranslation('registration') : getTranslation('login')} onClick={() => { props.contentType === 'LOGIN' ? props.onContentTypeChanged('REGISTER') : props.onContentTypeChanged('LOGIN'); }} />)}

					<button className='text-white hover:text-gold text-sm font-slab transition ' onClick={() => { props.contentType === 'FORGOT_PASSWORD' ? props.onContentTypeChanged('LOGIN') : props.onContentTypeChanged('FORGOT_PASSWORD'); }}>{props.contentType === 'FORGOT_PASSWORD' ? getTranslation('back-to-login') : getTranslation('did-you-forget-your-password')}</button>

				</div>

			</ModalContent>


		</Modal>
	);
}