import React, { useState, useEffect } from 'react';
import { useAuth } from 'providers/AuthProvider';
import { useFormContext } from 'react-hook-form';

import FormInput from 'elements/input/FormInput';

import { getTranslation } from 'utils/DictionaryUtils';

import ShopCustomerControl from 'services/ShopCustomerControl';
import { ModalFeedback } from 'elements/modal/Modal';

export default function Profile(props) {
    useEffect(() => {
        const timer = setTimeout(() => { props.onFeedbackHide(); }, 2000);
        return () => clearTimeout(timer);
    }, [props, props.feedback]);

    const { reset } = useFormContext();

    const { userData } = useAuth();

    const [billNameError, setBillNameError] = useState(null);
    const [billCountryError, setBillCountryError] = useState(null);
    const [billZipError, setBillZipError] = useState(null);
    const [billStateError, setBillStateError] = useState(null);
    const [billCityError, setBillCityError] = useState(null);
    const [billAddressError, setBillAddressError] = useState(null);
    const [billTaxError, setBillTaxError] = useState(null);

    const [shippingNameError, setShippingNameError] = useState(null);
    const [shippingCountryError, setShippingCountryError] = useState(null);
    const [shippingZipError, setShippingZipError] = useState(null);
    const [shippingStateError, setShippingStateError] = useState(null);
    const [shippingCityError, setShippingCityError] = useState(null);
    const [shippingAddressError, setShippingAddressError] = useState(null);
    const [shippingCommentError, setShippingCommentError] = useState(null);

    // const [userNameError, setUserNameError] = useState(null);
    const [emailError, setEmailError] = useState(null);
    const [phoneError, setPhoneError] = useState(null);
    const [passwordError, setPasswordError] = useState(null);
    const [passwordAgainError, setPasswordAgainError] = useState(null);

    const [birthdayError, setBirthdayError] = useState(null);
    const [paymentMethodError, setPaymentMethodError] = useState(null);
    const [subscribeNewsletterError, setSubscribeNewsletterError] = useState(null);

    const [countryOptions, setCountryOptions] = useState(null);
    const [paymentMethodOptions, setPaymentMethodOptions] = useState(null);

    useEffect(() => {
        if (props.userData) {
            reset(props.userData);
            console.log('Reset by props.');
        } else if (userData) {
            reset(userData);
            console.log('Reset by provider.');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userData, props.userData]);

    useEffect(() => {
        handleValidation(props.validationData);
    }, [props.validationData]);

    useEffect(() => {
        ShopCustomerControl.getCountrySelection().then(result => {
            result.unshift({ name: getTranslation('please-select'), id: '', default: true });
            setCountryOptions(result);
        });
        ShopCustomerControl.getPaymentMethodSelection().then(result => {
            result.unshift({ label: getTranslation('please-select'), value: '', default: true });
            setPaymentMethodOptions(result);
        });
    }, []);

    const handleValidation = (validationData) => {
        if (validationData && validationData.properties) {

            if (validationData.properties.paymentMethod) setPaymentMethodError(validationData.properties.paymentMethod[0].message);
            if (validationData.properties.email) setEmailError(validationData.properties.email[0].message);
            if (validationData.properties.phone) setPhoneError(validationData.properties.phone[0].message);
            if (validationData.properties.birthday) setBirthdayError(validationData.properties.birthday[0].message);
            if (validationData.properties.subscribeNewsletter) setSubscribeNewsletterError(validationData.properties.subscribeNewsletter[0].message);

            if (validationData.properties.billName) setBillNameError(validationData.properties.billName[0].message);
            if (validationData.properties.billCountryId) setBillCountryError(validationData.properties.billCountryId[0].message);
            if (validationData.properties.billZip) setBillZipError(validationData.properties.billZip[0].message);
            if (validationData.properties.billState) setBillStateError(validationData.properties.billState[0].message);
            if (validationData.properties.billCity) setBillCityError(validationData.properties.billCity[0].message);
            if (validationData.properties.billAddress) setBillAddressError(validationData.properties.billAddress[0].message);
            if (validationData.properties.billTax) setBillTaxError(validationData.properties.billTax[0].message);

            if (validationData.properties.shippingName) setShippingNameError(validationData.properties.shippingName[0].message);
            if (validationData.properties.shippingCountryId) setShippingCountryError(validationData.properties.shippingCountryId[0].message);
            if (validationData.properties.shippingZip) setShippingZipError(validationData.properties.shippingZip[0].message);
            if (validationData.properties.shippingState) setShippingStateError(validationData.properties.shippingState[0].message);
            if (validationData.properties.shippingCity) setShippingCityError(validationData.properties.shippingCity[0].message);
            if (validationData.properties.shippingAddress) setShippingAddressError(validationData.properties.shippingAddress[0].message);
            if (validationData.properties.shippingComment) setShippingCommentError(validationData.properties.shippingComment[0].message);

            if (validationData.properties.password) setPasswordError(validationData.properties.password[0].message);
            if (validationData.properties.passwordAgain) setPasswordAgainError(validationData.properties.passwordAgain[0].message);

        }
    }

    return (
        <form className='flex flex-col gap-8' /*onSubmit={handleSubmit(onSubmit)}*/>

            <div className={'grid gap-4 ' + (props.gridClassName || '')}>

                <Block title={getTranslation('billing-data')} {...props}>

                    <FormInput modal={props.modal} label={getTranslation('name')} mandatory={props.markMandatoryFields} value='billName' error={billNameError} onChange={() => setBillNameError(null)} />
                    <FormInput modal={props.modal} label={getTranslation('country')} mandatory={props.markMandatoryFields} value='billCountryId' error={billCountryError} optionList={countryOptions && countryOptions.map((item, index) => <option className='text-gold bg-black' key={item.id} value={item.id} default={item.default}>{item.name}</option>)} onChange={() => setBillCountryError(null)} />
                    <FormInput modal={props.modal} label={getTranslation('zip-code')} mandatory={props.markMandatoryFields} value='billZip' error={billZipError} onChange={() => setBillZipError(null)} />
                    <FormInput modal={props.modal} label={getTranslation('state')} mandatory={props.markMandatoryFields} value='billState' error={billStateError} onChange={() => setBillStateError(null)} />
                    <FormInput modal={props.modal} label={getTranslation('city')} mandatory={props.markMandatoryFields} value='billCity' error={billCityError} onChange={() => setBillCityError(null)} />
                    <FormInput modal={props.modal} label={getTranslation('address')} mandatory={props.markMandatoryFields} value='billAddress' error={billAddressError} onChange={() => setBillAddressError(null)} />
                    <FormInput modal={props.modal} label={getTranslation('tax-number')} value='billTax' error={billTaxError} onChange={() => setBillTaxError(null)} />
                    <FormInput modal={props.modal} label={getTranslation('billing-is-company')} type='checkbox' value='billIsCompany' />

                </Block>

                <Block title={getTranslation('shipping-data')} {...props}>

                    <FormInput modal={props.modal} label={getTranslation('name')} mandatory={props.markMandatoryFields} value='shippingName' error={shippingNameError} onChange={() => setShippingNameError(null)} />
                    <FormInput modal={props.modal} label={getTranslation('country')} mandatory={props.markMandatoryFields} value='shippingCountryId' error={shippingCountryError} optionList={countryOptions && countryOptions.map((item, index) => <option className='text-gold bg-black' key={item.id} value={item.id} default={item.default}>{item.name}</option>)} onChange={() => setShippingCountryError(null)} />
                    <FormInput modal={props.modal} label={getTranslation('zip-code')} mandatory={props.markMandatoryFields} value='shippingZip' error={shippingZipError} onChange={() => setShippingZipError(null)} />
                    <FormInput modal={props.modal} label={getTranslation('state')} mandatory={props.markMandatoryFields} value='shippingState' error={shippingStateError} onChange={() => setShippingStateError(null)} />
                    <FormInput modal={props.modal} label={getTranslation('city')} mandatory={props.markMandatoryFields} value='shippingCity' error={shippingCityError} onChange={() => setShippingCityError(null)} />
                    <FormInput modal={props.modal} label={getTranslation('address')} mandatory={props.markMandatoryFields} value='shippingAddress' error={shippingAddressError} onChange={() => setShippingAddressError(null)} />
                    <FormInput modal={props.modal} label={getTranslation('comment')} value='shippingComment' error={shippingCommentError} onChange={() => setShippingCommentError(null)} />

                </Block>

                <Block title={getTranslation('personal-data')} {...props}>

                    {/* <FormInput modal={props.modal} label={getTranslation('user-name')} value='userName' error={userNameError} /> */}
                    <FormInput modal={props.modal} label={getTranslation('email-address')} mandatory={props.markMandatoryFields} value='email' error={emailError} onChange={() => setEmailError(null)} />
                    <FormInput modal={props.modal} label={getTranslation('phone-number')} mandatory={props.markMandatoryFields} value='phone' error={phoneError} onChange={() => setPhoneError(null)} />
                    <FormInput modal={props.modal} label={getTranslation('password')} type='password' value='password' error={passwordError} onChange={() => setPasswordError(null)} />
                    <FormInput modal={props.modal} label={getTranslation('password-again')} type='password' value='passwordAgain' error={passwordAgainError} onChange={() => setPasswordAgainError(null)} />

                </Block>

                <Block title={getTranslation('additional-data')} {...props}>

                    <FormInput modal={props.modal} label={getTranslation('birthday')} type='date' value='birthday' error={birthdayError} onChange={() => setBirthdayError(null)} />
                    <FormInput modal={props.modal} label={getTranslation('payment-method')} mandatory={props.markMandatoryFields} value='paymentMethod' error={paymentMethodError} optionList={paymentMethodOptions && paymentMethodOptions.map((item, index) => <option className='text-gold bg-black' key={item.value} value={item.value} default={item.default}>{item.label}</option>)} onChange={() => setPaymentMethodError(null)} />
                    <FormInput modal={props.modal} label={getTranslation('subscribe-newsletter')} type='checkbox' value='subscribeNewsletter' error={subscribeNewsletterError} onChange={() => setSubscribeNewsletterError(null)} />

                </Block>

            </div>

            {props.feedback && <ModalFeedback data={props.feedback} />}

        </form>
    );
}

function Block(props) {
    return (
        <div className={'p-6 flex flex-col gap-6 ' + (props.blockClassName || '')}>

            <div className='heading-small text-sm text-white'>{props.title}</div>

            <div className={'grid gap-4 ' + (props.blockGridClassName || '')}>
                {props.children}
            </div>

        </div>
    );
}