import { useState } from 'react';
import { useAuth } from 'providers/AuthProvider';
import { FormProvider, useForm } from 'react-hook-form';

import Profile from 'components/Profile';
import Modal, { ModalHeader, ModalContent, ModalButton } from 'elements/modal/Modal';

import ShopCustomerControl from 'services/ShopCustomerControl';
import { getTranslation } from 'utils/DictionaryUtils';

export default function ModalAuth(props) {
    const [feedback, setFeedback] = useState(null);
    const [validationData, setValidationData] = useState(null);

    const methods = useForm({ mode: 'onChange', defaultValues: {} });
    const { handleSubmit } = methods;

    const { userData, handleLogout } = useAuth();

    const onSubmit = data => {
        setValidationData(null);

        ShopCustomerControl.update(data).then(result => {
            if (result.successful)
                setFeedback({ type: 'SUCCESS', message: getTranslation('profile-success-feedback') });
            else {
                setFeedback({ type: 'ERROR', message: getTranslation('profile-error-feedback') });
                setValidationData(result);
            }
        });
    };

    return (
        <Modal className='min-w-[100vw] sm:min-w-min' {...props}>

            <ModalHeader {...props}>
                <div className='heading'>{getTranslation('my-profile')}</div>
                {userData && <div className='text-white text-sm font-slab pt-2'>Üdvözlünk újra itt, {userData.userName}!</div>}
            </ModalHeader>

            <FormProvider {...methods}>
                <ModalContent disableVerticalPadding>

                    <Profile
                        modal
                        validationData={validationData}
                        blockClassName='rounded bg-black shadow-xl min-w-[220px]'
                        gridClassName='grid-cols-1 sm:grid-cols-2'
                        blockGridClassName='grid-cols-1 md:grid-cols-2'
                        feedback={feedback}
                        onFeedbackHide={() => setFeedback(null)} />

                </ModalContent>

                <ModalContent fixed className='w-full flex flex-col sm:flex-row gap-4'>
                    <ModalButton className='flex-1' label={getTranslation('logout')} onClick={() => { handleLogout(); props.onClose(); }} />
                    <ModalButton className='flex-1' label={getTranslation('save-data')} onClick={() => { handleSubmit(onSubmit)() }} />
                </ModalContent>

            </FormProvider>

        </Modal>
    );
}