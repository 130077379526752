import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { ModalFeedback } from 'elements/modal/Modal';

export default function FormInput(props) {
    const { register, control } = useFormContext();

    const currentValue = useWatch({ control, name: props.value, });

    const modalClassName = 'modal border-gold text-white placeholder:text-gold font-light';
    const modalErrorClassName = 'border-red-800 text-red-800 placeholder:text-red-800';
    const modalHintClassName = 'text-gold';
    const modalCheckboxClassName = 'text-white text-sm';

    const normalClassName = 'font-normal';
    const normalErrorClassName = 'border-red-800 text-red-800 placeholder:text-red-800';
    const normalHintClassName = '';
    const normalCheckboxClassName = 'text-sm';

    const className = 'w-full accent-gold outline-none bg-transparent border-b py-2 font-slab ' + (!props.error ? (props.modal ? modalClassName : normalClassName) : (props.modal ? modalErrorClassName : normalErrorClassName));

    const isHintVisible = () => {
        if (props.type !== 'checkbox' && !props.optionList && currentValue !== null && currentValue !== '')
            return true;
        else if (props.optionList)
            return true;
        return false;
    };

    useEffect(() => {
        if (props.onChange)
            props.onChange(currentValue);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentValue]);

    const getLabel = () => {
        return props.label + (props.mandatory ? ' *' : '');
    };

    return (
        <div className={'relative flex flex-col ' + (props.className || '')}>

            <div className={'absolute -top-2 left-0 font-slab text-[9px] tracking-wider transition z-10 ' + (props.modal ? modalHintClassName : normalHintClassName) + (isHintVisible() ? ' opacity-100' : ' opacity-0')}>{getLabel()}</div>

            {!props.optionList && <div className='flex flex-row items-center gap-4'>
                <input type={props.type} {...register(props.value)} className={className + (props.type === 'checkbox' ? 'h-8 w-auto' : '')} placeholder={getLabel()} id={props.value} />
                {props.type === 'checkbox' && <label className={'cursor-pointer py-2 font-slab flex-1 ' + (props.modal ? modalCheckboxClassName : normalCheckboxClassName)} htmlFor={props.value}>{getLabel()}</label>}
            </div>}

            {props.optionList && <select {...register(props.value)} className={className}>{props.optionList}</select>}

            {props.error && <ModalFeedback data={{ type: 'ERROR', message: props.error }} />}

        </div >
    );
}