import { FaFacebookSquare, FaInstagramSquare } from 'react-icons/fa';

import { getWebsite } from 'utils/WebsiteUtils.js';
import { getValueFromParameterMap } from 'cms/NBossCMS.js';
import { getTranslation } from 'utils/DictionaryUtils';

export default function SocialLister(props) {
    return (
        <div className={'w-full flex gap-4 overflow-hidden ' + (props.className || '')}>
            <div className={'2xl:flex-1 ' + (props.titleClassName || '')}>{getTranslation('follow-us')}:</div>
            <div className='text-4xl flex flex-row gap-2'>
                <a href={getValueFromParameterMap(getWebsite().globalContent, 'facebook-url')} className={'transition ' + (props.itemClassName || '')}><FaFacebookSquare /></a>
                <a href={getValueFromParameterMap(getWebsite().globalContent, 'instagram-url')} className={'transition ' + (props.itemClassName || '')}><FaInstagramSquare /></a>
            </div>
        </div>
    );
}