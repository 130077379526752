import Lister, { ListerItem } from 'elements/Lister.js';
import { FaInstagramSquare } from 'react-icons/fa';

import { getValueFromParameterMap } from 'cms/NBossCMS';
import { getWebsite } from 'utils/WebsiteUtils';
import { getTranslation } from 'utils/DictionaryUtils';

export default function InstagramLister(props) {
    const list = (getWebsite().globalContent && getValueFromParameterMap(getWebsite().globalContent, 'instagram-posts'));

    return (
        <Lister title={!props.disableTitle && getTranslation('follow-us-instagram')} preload={5}>

            {list && list.map((item) => (<InstagramItem key={item.key} data={item} />))}

        </Lister>
    );
}

function InstagramItem(props) {
    let _data = {
        image: getValueFromParameterMap(props.data, 'image') && getValueFromParameterMap(props.data, 'image').downloadUrl,
        url: getValueFromParameterMap(props.data, 'url'),
    }

    return (
        <ListerItem className='relative group overflow-hidden' href={_data.url} newWindow>

            <div className='absolute w-full h-full bg-center bg-no-repeat bg-cover lister-item-image-group-hover' style={{ backgroundImage: 'url(' + _data.image + ')' }}></div>

            <FaInstagramSquare className='text-white text-7xl z-10 group-hover:scale-100 scale-105 opacity-0 group-hover:opacity-95 transition duration-medium' />

        </ListerItem>
    );
}