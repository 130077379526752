
import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

import NavigationLister from 'components/NavigationLister';
import PageHeading from 'elements/PageHeading';

import { NBossCMSPage, getValueFromParameterMap } from 'cms/NBossCMS';
import globalConfig from 'config/globalConfig'
import { getQueryVariable } from 'utils/GenericUtils';
import CmsFrontendControl from 'services/CmsFrontendControl';
import { getTranslation } from 'utils/DictionaryUtils';

export default function SearchPage(props) {
    const [page, setPage] = useState(null);
    const [term, setTerm] = useState('');
    const [result, setResult] = useState(null);

    let location = useLocation();

    const _getQueryVariable = () => {
        let _term = getQueryVariable(location.search, globalConfig.search.queryParam) || '';
        if (_term !== '')
            _term = decodeURIComponent(_term);
        return _term;
    };

    useEffect(() => {
        let _term = _getQueryVariable();

        setTerm(_term);

        if (_term !== '')
            query(_term);
        else
            setResult(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    // useEffect(() => {
    //     console.log(result)
    // }, [result]);

    const query = (term) => {
        CmsFrontendControl.search(term).then(result => { setResult(result) });
    }

    let _data = {
        title: getValueFromParameterMap(page, 'title'),
        termTitle: getValueFromParameterMap(page, 'term-title'),
        countTitle: getValueFromParameterMap(page, 'count-title'),
        noResultTitle: getValueFromParameterMap(page, 'no-result-title'),
    }

    const getContent = () => {
        const str = _data.termTitle + ' <strong>' + term + '</strong><br/>';

        if (result && result.length > 0)
            return str + _data.countTitle + ' <strong>' + result.length + '</strong>';
        else
            return str + _data.noResultTitle;
    };

    return (
        <NBossCMSPage pageprops={props} pageDataReady={page => { setPage(page) }} className='w-full flex flex-col site-main-gap'>

            <PageHeading
                loading={(page) ? false : true}
                title={_data.title}
                content={getContent()} />

            {result && <div className='site-content-grid site-main-gap'>
                {result.map((item, index) => <ResultItem key={index} data={item} />)}
            </div>}

            <NavigationLister />

        </NBossCMSPage>
    );
};

function ResultItem(props) {
    return (
        <div className='bg-gray-100 p-6 flex flex-col gap-4'>

            <div>
                <div className='font-slab text-xs'>{props.data.itemType}</div>
                <div className='' dangerouslySetInnerHTML={{ __html: props.data.name }} />
            </div>

            <Link to={props.data.url} className='button plain dark'>{getTranslation('visit')}</Link>

        </div>
    );
}