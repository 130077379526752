import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

export default function Toast(props) {
    const [message, setMessage] = useState(null);

    useEffect(() => {
        if (props.message)
            setMessage(props.message);
        const timer = setTimeout(() => { props.onHide(); }, 2000);
        return () => clearTimeout(timer);
    }, [props, props.message]);

    return (
        <motion.button
            onClick={props.onHide}
            className={'fixed z-50 top-6 left-0 w-full flex justify-center ' + (props.className || '')}
            initial={props.message ? 'visible' : 'hidden'}
            animate={props.message ? 'visible' : 'hidden'}
            // transition={{ duration: 0.15, type: 'spring', stiffness: 150 }}
            variants={{ visible: { translateY: 0, opacity: 1, display: 'flex' }, hidden: { translateY: -90, opacity: 0, transitionEnd: { display: 'none' } } }}
            onAnimationComplete={definition => { definition === 'hidden' ? setMessage(null) : console.log('') }}>

            <div className='black-gradient-opacity rounded shadow-xl p-4 text-white'>
                <div className='text-sm font-slab tracking-wider' dangerouslySetInnerHTML={{ __html: message }} />
            </div>

        </motion.button >
    )
}