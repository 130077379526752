import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { TfiAngleLeft, TfiAngleRight } from 'react-icons/tfi';

const transitionDuration = 1;
const duration = 10;

export default function Slider(props) {
    const [hover, setHover] = useState(false);
    const [activeIndex, setActiveIndex] = useState(null);

    let _data = {
        list: props.data && props.data.parameterMap.itemList.value.contentList
    }

    let getNext = () => { return activeIndex + 1 >= _data.list.length ? 0 : activeIndex + 1; }
    let getPrev = () => { return activeIndex - 1 < 0 ? _data.list.length - 1 : activeIndex - 1; }

    useEffect(() => {
        if (props.data != null)
            setActiveIndex(0);
    }, [props.data])

    useEffect(() => {
        let timer = setTimeout(() => {
            setActiveIndex(getNext());
        }, duration * 1000);
        return () => clearTimeout(timer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeIndex])

    return (
        <div className={'relative w-full min-h-full ' + (props.className || '')} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>

            <div className='relative aspect-square sm:aspect-video lg:aspect-[5/2] 2xl:min-h-full bg-gray-100 overflow-hidden'>

                {_data.list && _data.list.map((item, index) => <Slide key={index} active={activeIndex === index} data={item} />)}

                {(_data.list && _data.list[activeIndex]) && <div className='absolute w-full h-full pb-1 flex flex-row justify-between'>

                    {_data.list[activeIndex].parameterMap['internalUrl'].value && <Link className='flex-1 transition duration-medium hover:backdrop-grayscale' to={_data.list[activeIndex].parameterMap['internalUrl'].value} />}
                    {_data.list[activeIndex].parameterMap['externalUrl'].value && <a className='flex-1 transition duration-medium hover:backdrop-grayscale' href={_data.list[activeIndex].parameterMap['externalUrl'].value} target='_blank' rel='noreferrer' to={_data.list[activeIndex].parameterMap['internalUrl'].value}> </a>}

                    <NavigationButton visible={hover} onClick={() => setActiveIndex(getNext())}><TfiAngleLeft /></NavigationButton>
                    <NavigationButton visible={hover} onClick={() => setActiveIndex(getPrev())} right><TfiAngleRight /></NavigationButton>

                </div>}

            </div>

            <div className='2xl:absolute 2xl:-bottom-12 w-full 2xl:h-12 flex flex-row items-center gap-4 pt-2'>

                <div className='flex-1 relative w-full 2xl:h-full flex items-center'>
                    {_data.list && _data.list.map((item, index) => <Text key={index} active={activeIndex === index} data={item} />)}
                </div>

                <div className='hidden sm:flex flex-row gap-2'>
                    {_data.list && _data.list.map((item, index) => <Indicator key={index} active={activeIndex === index} onClick={() => setActiveIndex(index)} />)}
                </div>

            </div>

        </div>
    );
}

function NavigationButton(props) {
    return (
        <button onClick={() => props.onClick()} className={'w-12 absolute top-0 h-full bg-white/50 hover:bg-white/75 backdrop-blur-sm flex items-center justify-center shadow-xl transition duration-medium ' + (props.visible ? 'opacity-100 ' : 'opacity-0 ') + (props.right ? ('right-0 ' + (props.visible ? 'translate-x-0' : 'translate-x-full')) : ('left-0 ' + (props.visible ? 'translate-x-0' : '-translate-x-full')))}>
            <span className='text-xl'>{props.children}</span>
        </button>
    );
}

function Slide(props) {
    return (
        <motion.div
            className='absolute w-full h-full flex overflow-hidden'
            transition={{ duration: transitionDuration }}
            initial={'hidden'}
            animate={props.active ? 'visible' : 'hidden'}
            variants={{ 'visible': { opacity: 1 }, 'hidden': { opacity: 0 } }}>

            {props.data.parameterMap['image'].value &&
                <motion.div
                    className='bg-cover bg-center w-full h-full'
                    style={{ backgroundImage: 'url(' + props.data.parameterMap['image'].value.downloadUrl + ')' }}
                    transition={{ ease: 'linear', duration: duration }}
                    initial={'start'}
                    animate={props.active ? 'finish' : 'start'}
                    variants={{ 'start': { scale: 1.1, rotate: 0.25, transition: { duration: transitionDuration } }, 'finish': { scale: 1, rotate: 0 } }}>
                </motion.div>}

            <div className='absolute w-full h-1 bg-white bottom-0 bg-opacity-50 backdrop-filter backdrop-blur-sm' >
                <motion.div
                    className='h-full bg-white bg-opacity-50'
                    transition={{ ease: 'linear', duration: duration }}
                    initial={'start'}
                    animate={props.active ? 'finish' : 'start'}
                    variants={{ 'start': { width: '0%', transition: { duration: transitionDuration } }, 'finish': { width: '100%' } }} />
            </div>

        </motion.div>
    );
}

function Text(props) {
    return (
        <motion.div
            className='text-sm font-slab w-full 2xl:absolute'
            transition={{ duration: transitionDuration }}
            initial={'hidden'}
            animate={props.active ? 'visible' : 'hidden'}
            variants={{ 'visible': { opacity: 1, display: 'block' }, 'hidden': { opacity: 0, display: 'none' } }}>

            <span className='font-bold'>{props.data.parameterMap['name'].value}</span> | {props.data.parameterMap['description'].value}

        </motion.div>
    );
}

function Indicator(props) {
    return (<button className={'w-2 h-2 transition duration-medium hover:scale-110 ' + (props.active ? 'bg-gold' : 'bg-gray-100')} onClick={() => props.onClick()} />);
}