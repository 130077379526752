import globalConfig from '../config/globalConfig.js'

export const startsWithVowel = s => {
    const vowels = ['a', 'á', 'e', 'é', 'i', 'í', 'o', 'ó', 'ö', 'ő', 'u', 'ú', 'ü', 'ű'];
    return vowels.some(v => s.toLowerCase().startsWith(v));
};

export const getVowelAdjective = s => {
    return s ? (startsWithVowel(s) ? 'az' : 'a') : 'a(z)';
};

export const separateThousands = s => {
    return s.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

export const getDateString = (date, options) => {
    var _options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    };

    if (options && options.time) {
        _options['hour'] = 'numeric';
        _options['minute'] = 'numeric';
    }

    return date.toLocaleDateString('hu-HU', _options);
};

export const getQueryVariable = (search, variable) => {
    var query = search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] === variable) { return pair[1]; }
    }
    return (false);
}

export const getSearchUrl = (term) => {
    if (term && term !== '') return '/' + globalConfig.search.path + '?' + globalConfig.search.queryParam + '=' + term;
    else return '/' + globalConfig.search.path;
}