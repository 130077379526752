
import { useState } from 'react';

import InstagramLister from 'components/InstagramLister.js';
import About from 'components/About.js';
import AddressMap from 'elements/AddressMap.js';
import NavigationLister from 'components/NavigationLister';

import { NBossCMSPage, getValueFromParameterMap } from 'cms/NBossCMS.js';
import { getWebsite } from 'utils/WebsiteUtils';
import { getTranslation } from 'utils/DictionaryUtils';

export default function ShopListPage(props) {
    const [page, setPage] = useState(null);

    return (
        <NBossCMSPage pageprops={props} pageDataReady={page => { setPage(page) }} className='w-full flex flex-col site-main-gap'>

            <h1 className='heading-large'>{getTranslation('our-shops')}</h1>

            <div className='grid site-content-grid site-main-gap'>
                <ShopList data={page} />
                <About className='col-span-4 2xl:col-span-1' maxHeight />
            </div>

            <NavigationLister />

            <InstagramLister />

        </NBossCMSPage>
    );
};

function ShopList(props) {
    const list = getWebsite().globalContent && getValueFromParameterMap(getWebsite().globalContent, 'shop-list');

    return (
        <div className='col-span-4 flex flex-col lg:flex-row site-main-gap'>

            {list && list.map((item, index) => <Shop key={item.id} data={item} />)}

        </div>
    );
}


function Shop(props) {
    let _data = {
        address: getValueFromParameterMap(props.data, 'cim'),
        description: getValueFromParameterMap(props.data, 'description'),
        image: getValueFromParameterMap(props.data, 'image') && getValueFromParameterMap(props.data, 'image').downloadUrl,
        lat: getValueFromParameterMap(props.data, 'coordinate-lat'),
        lng: getValueFromParameterMap(props.data, 'coordinate-lng'),
        mapUrl: getValueFromParameterMap(props.data, 'map-url'),
    }

    return (
        <div className={'flex-1 flex flex-col site-main-gap ' + (props.className || '')}>

            <div className='h-64 bg-gray-100 bg-center bg-no-repeat bg-cover flex items-center justify-center' style={{ backgroundImage: 'url(' + _data.image + ')' }}>
                <div className='text-white uppercase font-slab font-medium text-lg tracking-widest z-10 drop-shadow-lg'>{_data.address}</div>
            </div>

            <div className='w-full relative bg-gray-100 h-64'>
                <AddressMap name={_data.address} lat={_data.lat} lng={_data.lng} mapUrl={_data.mapUrl} />
            </div>

            <div className='leading-7' dangerouslySetInnerHTML={{ __html: _data.description }} />

        </div>
    );
}