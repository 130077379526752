
import { useState, useEffect } from 'react';

import ProductLister from 'components/ProductLister';
import Lister, { ListerItem } from 'elements/Lister.js';
import PageHeading from 'elements/PageHeading';

import { NBossCMSPage, getValueFromParameterMap } from 'cms/NBossCMS';
import { getGenderIcon } from 'utils/NeroliUtils';

export default function ProductFilterPage(props) {
    const [page, setPage] = useState(null);

    const [occasion, setOccaison] = useState(null);
    const [family, setFamily] = useState(null);
    const [component, setComponent] = useState('');
    const [manufacturer, setManufacturer] = useState('');
    const [gender, setGender] = useState(null);

    const [query, setQuery] = useState(null);

    const [count, setCount] = useState(0);

    let _data = {
        title: getValueFromParameterMap(page, 'title'),
        description: getValueFromParameterMap(page, 'description'),
        occasions: getValueFromParameterMap(page, 'alkalmakEsEvszakok'),
        occasionsTitle: getValueFromParameterMap(page, 'parfumvalaszto-alkalom-es-evszak-szerint-cim'),
        family: getValueFromParameterMap(page, 'illatcsalad'),
        familyTitle: getValueFromParameterMap(page, 'parfumvalaszto-illatcsaladok-cim'),
        component: getValueFromParameterMap(page, 'osszetevo'),
        componentTitle: getValueFromParameterMap(page, 'parfumvalaszto-osszetevo-szerint-cim'),
        componentDescription: getValueFromParameterMap(page, 'parfumvalaszto-osszetevo-szerint-leiras'),
        componentDefault: getValueFromParameterMap(page, 'osszetevo-alapertelmezett'),
        manufacturer: getValueFromParameterMap(page, 'gyarto'),
        manufacturerTitle: getValueFromParameterMap(page, 'gyarto-szerint-cim'),
        manufacturerDescription: getValueFromParameterMap(page, 'gyarto-leiras'),
        manufacturerDefault: getValueFromParameterMap(page, 'gyarto-alapertelmezet'),
        gender: getValueFromParameterMap(page, 'nemek') && getValueFromParameterMap(page, 'nemek').contentList,
        genderTitle: getValueFromParameterMap(page, 'nemek-cim'),
        genderDescription: getValueFromParameterMap(page, 'nemek-leiras'),
        genderList: getValueFromParameterMap(page, 'nemek'),
    }

    useEffect(() => {
        let _query = {};

        if (occasion && occasion !== '') _query['alkalmakEsEvszakok'] = occasion;
        if (family && family !== '') _query['illatcsalad'] = family;
        if (component && component !== '') _query['osszetevo'] = component;
        if (manufacturer && manufacturer !== '') _query['gyarto'] = manufacturer;
        if (gender && gender !== '') _query['nemek'] = gender;

        setQuery(_query);

    }, [occasion, family, component, manufacturer, gender]);

    const addDefault = (list, defaultLabel) => {
        if (list) {
            let _list = Array.from(list);
            _list.unshift({ name: defaultLabel, id: '', default: true });
            return _list;
        }
        return null;
    };

    return (
        <NBossCMSPage pageprops={props} pageDataReady={page => { setPage(page) }} className='w-full flex flex-col site-main-gap'>

            <PageHeading loading={_data.title ? false : true} title={_data.title} content={_data.description} />

            {page && <div className='site-content-grid site-main-gap'>
                <SelectLister className='col-span-2' title={_data.componentTitle} description={_data.componentDescription} list={addDefault(_data.component, _data.componentDefault)} capitalize value={component} onChange={(value) => setComponent(value)} />
                <SelectLister className='col-span-2' title={_data.manufacturerTitle} description={_data.manufacturerDescription} list={addDefault(_data.manufacturer, _data.manufacturerDefault)} value={manufacturer} onChange={(value) => setManufacturer(value)} />
                <GenderLister title={_data.genderTitle} description={_data.genderDescription} list={_data.gender} value={gender} onChange={(value) => setGender(value)} />
            </div>}

            <OccasionLister title={_data.occasionsTitle} list={_data.occasions} value={occasion} onChange={(value) => setOccaison(value)} />
            <FamilyLister title={_data.familyTitle} list={_data.family} value={family} onChange={(value) => setFamily(value)} />

            <div className='flex flex-col gap-8'>
                <div className='flex flex-col gap-4'>
                    <h2 className='heading'>Keresési találatok</h2>
                    {count > 0 && <div className='leading-7'>A megadott feltételekre {count} találatot találtunk:</div>}
                </div>
                <ProductLister customQuery={query} onListDataChanged={(listData) => setCount(listData ? listData.count : 0)} />
            </div>

        </NBossCMSPage>
    );
};

function OccasionLister(props) {
    return (<Lister headingClassName='heading' title={props.title} preload={5}>{props.list && props.list.map((item) => (<OccasionItem key={item.id} data={item} selected={!props.value || (props.value === item.id)} onClick={() => props.onChange(props.value === item.id ? null : item.id)} />))}</Lister>);
};

function OccasionItem(props) {
    let _data = {
        name: getValueFromParameterMap(props.data, 'name'),
        image: getValueFromParameterMap(props.data, 'image') && getValueFromParameterMap(props.data, 'image').downloadUrl,
    }

    return (
        <ListerItem className={'relative group overflow-hidden transition ' + (props.selected ? 'opacity-100' : 'opacity-75 grayscale')} onClick={() => props.onClick()}>

            <div className={'absolute w-full h-full bg-center bg-no-repeat bg-cover lister-item-image-group-hover'} style={{ backgroundImage: 'url(' + _data.image + ')' }}></div>
            <div className='lister-item-image-label'>{_data.name}</div>

        </ListerItem>
    );
};

function FamilyLister(props) {
    return (<Lister headingClassName='heading' title={props.title} preload={5}>{props.list && props.list.map((item) => (<FamilyListerItem key={item.id} data={item} selected={!props.value || (props.value === item.id)} onClick={() => props.onChange(props.value === item.id ? null : item.id)} />))}</Lister>);
};

function FamilyListerItem(props) {
    let _data = {
        name: getValueFromParameterMap(props.data, 'name'),
        description: getValueFromParameterMap(props.data, 'description'),
        perfumeSelection: getValueFromParameterMap(props.data, 'perfumeSelection'),
        image: getValueFromParameterMap(props.data, 'image') && getValueFromParameterMap(props.data, 'image').downloadUrl,
    }

    const getNameMain = () => {
        if (_data.name.indexOf(':') > -1)
            return _data.name.substring(0, _data.name.indexOf(':'))
        return null;
    };

    const getNameSub = () => {
        if (_data.name.indexOf(':') > -1)
            return _data.name.substring(_data.name.indexOf(':') + 1)
        return _data.name;
    };

    return (
        <ListerItem className={'flex flex-col gap-4 relative group overflow-hidden transition ' + (props.selected ? 'bg-gold text-white hover:bg-gold-light' : 'text-zinc-500 opacity-75 grayscale hover:bg-zinc-300')} onClick={() => props.onClick()}>

            <div className='font-slab font-bold uppercase tracking-wider'>{getNameMain()}</div>

            {_data.image && <div className='w-auto max-w-[75%] shadow overflow-hidden'><img className='-p-[10px]' src={_data.image} alt={getNameMain()} /></div>}

            <div className='font-slab capitalize text-sm tracking-wider'>{getNameSub()}</div>

        </ListerItem>
    );
};

function SelectLister(props) {
    return (
        <div className={'flex flex-col gap-6 justify-between ' + (props.className || '')}>

            <div className='col-span-3 flex flex-col gap-4'>
                <h2 className='heading'>{props.title}</h2>
                <div className='text-sm leading-6' dangerouslySetInnerHTML={{ __html: props.description }} />
            </div>

            <select className='col-span-2 border border-gray-100 p-4' value={props.value} onChange={(e) => props.onChange(e.target.value)}>
                {props.list && props.list.map((item) => (<option key={item.id} value={item.id}>{props.capitalize ? (item.name.charAt(0).toUpperCase() + item.name.slice(1)) : item.name}</option>))}
            </select>

        </div>
    );
}

function GenderLister(props) {
    return (
        <div className='flex flex-col gap-6 justify-between'>

            <div className='col-span-3 flex flex-col gap-4'>
                <h2 className='heading'>{props.title}</h2>
                <div className='text-sm leading-6' dangerouslySetInnerHTML={{ __html: props.description }} />
            </div>

            <div className='flex flex-row font-cursive text-3xl text-gray-100 justify-between items-end'>
                {props.list && props.list.map((item, index) => (<>{index ? <div>&nbsp;&nbsp;/&nbsp;&nbsp;</div> : null}<GenderItem key={item.id} data={item} selected={!props.value || (props.value === item.id)} onClick={() => props.onChange(props.value === item.id ? null : item.id)} /></>))}
            </div>

        </div>
    );
}

function GenderItem(props) {
    let _data = {
        name: getValueFromParameterMap(props.data, 'name'),
    }

    return (
        <button className={'flex flex-row transition ' + (props.selected ? 'text-gold hover:text-gold-light' : 'hover:text-zinc-300')} onClick={() => props.onClick()}>

            <div className='flex flex-col gap-2 items-center'>
                {getGenderIcon(props.data, '')}
                <div className='leading-none'>{_data.name}</div>
            </div>

        </button>
    );
}