import nBossConnection from 'services/nBossConnection.js';

class ShopGiftCardControl {
    constructor() {
        this.connection = nBossConnection.connection;
        this.CONTROL = 'shopGiftCardControl';
    }

    getGiftCardList() {
        return new Promise((resolve, reject) => {
            this.connection.post(`/${this.CONTROL}/getGiftCardList`, {}).then(response => resolve(response.data)).catch(reject);
        });
    }

    getSamplePackList() {
        return new Promise((resolve, reject) => {
            this.connection.post(`/${this.CONTROL}/getSamplePackList`, {}).then(response => resolve(response.data)).catch(reject);
        });
    }

    /**
    * @param {productKey} productKey
    * @param {quantity} quantity
    */
    addToBasket(productKey, quantity) {
        return new Promise((resolve, reject) => {
            this.connection.post(`/${this.CONTROL}/addToBasket`, { productKey, quantity }).then(response => resolve(response.data)).catch(reject);
        });
    }

}

export default new ShopGiftCardControl();