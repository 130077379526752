
export default function PageHeading(props) {
    return (
        <div className={'bg-gray-100 flex flex-col gap-4 p-4 xl:p-8 ' + (props.className || '')}>

            <div className='heading-small'>{props.title}</div>
            <div className='leading-7'>{props.content}</div>

        </div>
    );
}
