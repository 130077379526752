import { TfiCheck, TfiMinus } from 'react-icons/tfi';

export default function SwitchInput(props) {
    return (
        <button className={'rounded-full w-12 h-6 p-1 flex flex-row transition-all ' + (props.disabled ? 'cursor-disabled opacity-50' : '') + ' ' + (props.value ? 'bg-gold bg-opacity-100 justify-end' : 'bg-white bg-opacity-20 justify-start')} onClick={() => { if (!props.disabled) props.onChange(!props.value) }}>

            <div className={'relative rounded-full h-full aspect-square shadow transition ' + (props.value ? 'bg-white' : 'bg-gold')}>
                <TfiCheck className={'absolute text-gold text-[8px] left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 ' + (props.value ? 'opacity-100' : 'opacity-0')} />
                <TfiMinus className={'absolute text-[8px] left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 ' + (props.value ? 'opacity-0' : 'opacity-100')} />
            </div>

        </button>
    );
}
