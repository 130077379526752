
import { useState } from 'react';
import { TfiEmail, TfiMobile, TfiMapAlt } from 'react-icons/tfi';

import InstagramLister from 'components/InstagramLister.js';
import About from 'components/About.js';

import { NBossCMSPage, getValueFromParameterMap } from 'cms/NBossCMS.js';
import { getWebsite } from 'utils/WebsiteUtils';
import NavigationLister from 'components/NavigationLister';

export default function ContactPage(props) {
    const [page, setPage] = useState(null);

    let _data = {
        heading: getValueFromParameterMap(page, 'heading'),
    }

    return (
        <NBossCMSPage pageprops={props} pageDataReady={page => { setPage(page) }} className='w-full flex flex-col site-main-gap'>

            <h1 className='heading-large'>{_data.heading}</h1>

            <div className='grid site-content-grid site-main-gap'>
                <Contact data={page} />
                <About className='col-span-4 2xl:col-span-1' />
            </div>

            <NavigationLister />

            <InstagramLister />

        </NBossCMSPage>
    );
};

function Contact(props) {
    let _data = {
        shopList: getValueFromParameterMap(getWebsite().globalContent, 'shop-list'),
        impressumShop: getValueFromParameterMap(props.data, 'impressum-shop'),
        impressumHosting: getValueFromParameterMap(props.data, 'impressum-hosting'),
        openingHours: getValueFromParameterMap(props.data, 'opening-hours'),
        phone: getValueFromParameterMap(getWebsite().globalContent, 'phone'),
        email: getValueFromParameterMap(getWebsite().globalContent, 'email'),
    }

    return (
        <div className='col-span-4 grid site-content-one-less-grid site-main-gap'>

            <Block title='A NEROLI parfümériák címe' className='col-span-2' contentClassName='flex flex-col sm:flex-row gap-4'>

                {_data.shopList && _data.shopList.map((item, index) => <Shop key={item.id} data={item} />)}

            </Block>

            <Block title='Ügyfélszolgálat' className='col-span-2 sm:col-span-1 xl:col-span-2' contentClassName='gap-2'>

                {_data.phone && <DataLine icon={<TfiMobile />} href={'tel:' + _data.phone} label={_data.phone} />}
                {_data.email && <DataLine icon={<TfiEmail />} href={'mailto:' + _data.email} label={_data.email} />}

            </Block>

            <Block title='Nyitvatartás' className='col-span-2 sm:col-span-1'>

                <div dangerouslySetInnerHTML={{ __html: _data.openingHours }} />

            </Block>

            <Block title='Impresszum' className='col-span-2 xl:col-span-3' contentClassName='grid 2xl:grid-cols-2 gap-6'>

                <div dangerouslySetInnerHTML={{ __html: _data.impressumShop }} />
                <div dangerouslySetInnerHTML={{ __html: _data.impressumHosting }} />

            </Block>

        </div>
    );
}

function Block(props) {
    return (
        <div className={'bg-gray-100 p-6 flex flex-col gap-6 ' + (props.className || '')}>

            <h2 className='heading'>{props.title}</h2>

            <div className={'text-sm leading-6 ' + (props.contentClassName || '')}>
                {props.children}
            </div>

        </div>
    );
}

function Shop(props) {
    let _data = {
        address: getValueFromParameterMap(props.data, 'cim'),
        district: getValueFromParameterMap(props.data, 'district'),
        phone: getValueFromParameterMap(props.data, 'phone'),
        mapUrl: getValueFromParameterMap(props.data, 'map-url'),
    }

    return (
        <div className={'flex-1 flex flex-col ' + (props.className || '')}>

            <div><strong>{_data.district}</strong></div>
            {_data.address && <DataLine icon={<TfiMapAlt />} href={_data.mapUrl} label={_data.address} />}
            {_data.phone && <DataLine icon={<TfiMobile />} href={'tel:' + _data.phone} label={_data.phone} />}

        </div >
    );
}

export function DataLine(props) {
    return (
        <div className='flex flex-row items-center gap-2'>
            {props.icon}
            <a className='transition hover:text-gold' href={props.href}>{props.label}</a>
        </div>
    );
}