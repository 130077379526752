import nBossConnection from 'services/nBossConnection.js';

class ShopBasketControl {
    constructor() {
        this.connection = nBossConnection.connection;
        this.CONTROL = 'shopBasketControl';
    }

    getInfo() {
        return new Promise((resolve, reject) => {
            this.connection.post(`/${this.CONTROL}/getInfo`, {}).then(response => resolve(response.data)).catch(reject);
        });
    }

    /**
    * @param {productKey} productKey
    * @param {variantKey} variantKey
    * @param {quantity} quantity
    */
    add(productKey, variantKey, quantity) {
        return new Promise((resolve, reject) => {
            this.connection.post(`/${this.CONTROL}/add`, { productKey, variantKey, quantity }).then(response => resolve(response.data)).catch(reject);
        });
    }

    /**
    * @param {productKey} productKey
    * @param {variantKey} variantKey
    */
    remove(productKey, variantKey) {
        return new Promise((resolve, reject) => {
            this.connection.post(`/${this.CONTROL}/remove`, { productKey, variantKey }).then(response => resolve(response.data)).catch(reject);
        });
    }

    /**
    * @param {productKey} productKey
    * @param {variantKey} variantKey
    * @param {quantity} quantity
    */
    set(productKey, variantKey, quantity) {
        return new Promise((resolve, reject) => {
            this.connection.post(`/${this.CONTROL}/set `, { productKey, variantKey, quantity }).then(response => resolve(response.data)).catch(reject);
        });
    }

}

export default new ShopBasketControl();
