import { TfiClose } from 'react-icons/tfi';

import { separateThousands } from 'utils/GenericUtils';
import { getTranslation } from 'utils/DictionaryUtils.js';
import { useShop } from 'providers/ShopProvider';

const tableGap = 'gap-4'
const tableClassName = 'grid grid-cols-4 md:grid-cols-6 auto-cols-min ' + tableGap;

export default function Cart(props) {
    return (
        <div className={'flex flex-col gap-4 ' + (props.className || '')}>


            {props.headingEnabled && <h2 className={(props.headingClassName || '')} >{getTranslation('products')}</h2>}

            <CartItemLister {...props} />

            {props.headingEnabled && <h2 className={(props.headingClassName || '')} >{getTranslation('discounts')}</h2>}

            <CartDiscountLister {...props} />

            {props.headingEnabled && <h2 className={(props.headingClassName || '')} >{getTranslation('total')}</h2>}

            <CartSummary {...props} />

        </div>
    );
}

export function CartItemLister(props) {
    const { basketContent } = useShop();

    return (
        <div className='flex flex-col'>

            <div className={'flex flex-row border-b border-gold pb-4 ' + tableGap}>

                <div className={'flex-1 font-slab text-center tracking-widest uppercase ' + tableClassName + ' ' + (props.cartItemHeaderClassName || '')}>

                    <div className='col-span-2 text-left'>{getTranslation('product')}</div>
                    <div className='hidden md:block'>{getTranslation('packaging')}</div>
                    <div className='hidden md:block'>{getTranslation('price')}</div>
                    <div>{getTranslation('pieces')}</div>
                    <div className={props.disabled ? 'text-right' : ''}>{getTranslation('total')}</div>

                </div>

                {!props.disabled && <div className='w-6' />}

            </div>

            <div className='flex flex-col'>
                {(basketContent && basketContent.itemList) && basketContent.itemList.map(item => <CartItem key={item.id} data={item} {...props} />)}
            </div>

        </div>
    );
}


function CartItem(props) {
    const { removeFromBasket, setQuantity } = useShop();

    const updateQuantity = (quantity) => {
        setQuantity(props.data.productKey, props.data.variantKey, quantity);
    }

    const colClassName = 'flex justify-centerr items-center text-sm whitespace-nowrap ' + (props.cartItemColClassName || '');

    return (
        <div className={'flex flex-row border-b border-gold border-opacity-25 last:border-opacity-100 ' + tableGap}>

            <div className={'flex-1 items-center py-4 ' + tableClassName}>

                <div className={'col-span-2 font-slab font-bold flex justify-start items-start text-gold ' + (props.cartItemColHighlightClassName || '')}>{props.data.productName}</div>

                <div className={'hidden md:block justify-center ' + colClassName}>{props.data.variantName}</div>

                <div className={'hidden md:block justify-center ' + colClassName}>{separateThousands(props.data.unitPrice)}&nbsp;<span className='text-xs'>Ft</span></div>

                <div className={'justify-center ' + colClassName}>
                    <input disabled={props.disabled} className={'w-12 text-right font-bold p-1 border ' + (props.disabled ? 'border-gray-100' : 'border-gold bg-transparent') + ' ' + (props.itemInputClassName || '')} type='number' min='1' max='5' value={props.data.quantity} onChange={e => updateQuantity(e.target.value)}></input>
                </div>

                <div className={'' + (props.disabled ? 'justify-end ' : 'justify-center ') + colClassName}>{separateThousands(props.data.totalPrice)}&nbsp;<span className='text-xs'>Ft</span></div>

            </div>

            {!props.disabled && <div className='w-6 flex justify-end items-center'>
                <button className='w-6 aspect-square border border-gold text-gold font-bold flex items-center justify-center transition hover:bg-gold hover:text-white' onClick={() => removeFromBasket(props.data.productKey, props.data.variantKey)}><TfiClose className='text-xs' /></button>
            </div>}

        </div>
    );
}

export function CartDiscountLister(props) {
    const { basketContent } = useShop();

    return (
        <div className='flex flex-col gap-2'>
            {(basketContent && basketContent.discountList) && basketContent.discountList.map((item, index) => <DiscountItem key={index} data={item} {...props} />)}
        </div>
    );
}

function DiscountItem(props) {
    return (
        <div className={'flex items-center rounded p-4 tracking-wider ' + tableGap + ' ' + (props.cartDiscountItemClassName || '')}>

            <div className='flex-1' dangerouslySetInnerHTML={{ __html: props.data.title }} />

            <div className='text-sm'>{separateThousands(props.data.totalPrice)}&nbsp;<span className='text-xs'>Ft</span></div>

        </div>
    );
}

export function CartSummary(props) {
    const { basketContent } = useShop();

    return (
        <div className='w-full grid grid-cols-2 lg:grid-cols-4 gap-4'>

            {basketContent && <>
                <CartSummaryItem label={getTranslation('products')} value={basketContent.totalPrice} {...props} />
                <CartSummaryItem label={getTranslation('discount')} value={basketContent.discountPrice} {...props} />
                <CartSummaryItem label={getTranslation('shipping')} value={basketContent.transportPrice} {...props} />
                <CartSummaryItem label={getTranslation('total')} value={basketContent.payablePrice} {...props} />
            </>}

        </div>
    );
}

function CartSummaryItem(props) {
    return (
        <div className={'border border-gold flex flex-col gap-2 items-center justify-center ' + (props.cartSummaryItemClassName || '')}>

            <div className='font-slab tracking-wider'>{props.label}</div>
            <div className='text-[1.5em] font-bold'>{props.value ? separateThousands(props.value) : '0'}&nbsp;<span className='text-[0.75em]'>Ft</span></div>

        </div>
    );
}