import { useState } from 'react';
import { Link } from 'react-router-dom';

import Modal, { ModalHeader, ModalContent } from 'elements/modal/Modal';
import SwitchInput from 'elements/input/SwitchInput';

export default function ModalCookieConsent(props) {
    const [details, setDetails] = useState(false);

    const optionList = [
        { key: 'mandatory', mandatory: true, title: 'Feltétlenül szükséges Cookie-k', desciption: 'Ezek a sütik elengedhetetlenek a weboldalunkon elérhető szolgáltatások nyújtásához, valamint weboldalunk bizonyos funkcióinak használatához. A feltétlenül szükséges sütik használata nélkül weboldalunkon nem tudunk bizonyos szolgáltatásokat nyújtani Önnek.' },
        { key: 'function', title: 'Funkcionalitás Cookie-k', desciption: 'A sütik olyan szolgáltatásokat tesznek lehetővé ezen a weboldalon, amelyek segítik a weboldal működését vagy megkönnyítik a felhasználói élményt.' },
        { key: 'analytics', title: 'Analitikai Cookie-k', desciption: 'Az analitikai sütiket a weboldalunk fejlesztésére használjuk az Ön és a weboldal közötti interakciók mérésével.' },
        { key: 'personalization', title: 'Személyre szabási Cookie-k', desciption: 'A személyre szabási sütiket arra használjuk, hogy személyre szabott élményeket hozzunk létre, és személyre szabott ajánlatokat nyújtssunk Önnek a webhelyen végzett interakciói alapján.' },
    ];

    const inlineLinkClassName = 'text-gold transition hover:underline font-medium';

    return (
        <Modal className='max-w-[840px]' {...props}>

            <ModalHeader {...props} disableClose>
                <div className='heading'>Az Ön adatainak felelősségteljes felhasználása</div>
                <div className='text-white text-sm font-slab pt-2'>Cookie kezeléssel, és tárolt adatokkal kapcsolatos beállítások</div>
            </ModalHeader>

            <ModalContent disableVerticalPadding>

                <div className='text-white text-xs leading-6'>Mi és a partnereink információkat - például sütiket - tárolunk egy eszközön vagy hozzáférünk az eszközön tárolt információkhoz, és személyes adatokat - például egyedi azonosítókat és az eszköz által küldött alapvető információkat - kezelünk személyre szabott hirdetések és tartalom nyújtásához, hirdetés- és tartalomméréshez, nézettségi adatok gyűjtéséhez, valamint termékek kifejlesztéséhez és a termékek javításához. Az Ön engedélyével mi és a partnereink eszközleolvasásos módszerrel szerzett pontos geolokációs adatokat és azonosítási információkat is felhasználhatunk. A megfelelő helyre kattintva hozzájárulhat ahhoz, hogy mi és a partnereink a fent leírtak szerint adatkezelést végezzünk. Másik lehetőségként a hozzájárulás megadása vagy elutasítása előtt részletesebb információkhoz juthat, és megváltoztathatja beállításait. Felhívjuk figyelmét, hogy személyes adatainak bizonyos kezeléséhez nem feltétlenül szükséges az Ön hozzájárulása, de jogában áll tiltakozni az ilyen jellegű adatkezelés ellen. A beállításai csak erre a weboldalra érvényesek. Erre a webhelyre visszatérve vagy az adatvédelmi szabályzatunk segítségével bármikor megváltoztathatja a beállításait.</div>

                {details && <div className='flex flex-col pt-6'>{optionList && optionList.map((item) => (<Option key={item.key} data={item} />))}</div>}

                <div className='text-white text-xs leading-6 pt-6'>Részletes információkat az <Link className={inlineLinkClassName} to={'/'}>Adatvédelmi Irányelveben</Link> illetve <Link className={inlineLinkClassName} to={'/'}>Cookie Tájékoztatónban</Link> olvashat.</div>

            </ModalContent>

            <ModalContent fixed className='w-full flex flex-col sm:flex-row gap-4'>
                <button className='flex-1 button px-8' onClick={() => setDetails(!details)}>{details ? 'Kevesebb beállítás' : 'További beállítások'}</button>
                <button className='flex-1 button plain px-8' onClick={() => props.onClose()}>{details ? 'Mindet elfogadom' : 'Elfogadom'}</button>
            </ModalContent>

        </Modal>
    );
}

function Option(props) {
    const [open, setOpen] = useState(false);
    const [consent, setConsent] = useState(props.data.mandatory ? true : false);

    return (
        <div className='text-white border-b first:border-y border-gold flex flex-row justify-between items-center gap-6'>

            <div className='flex-1'>
                <div className='text-gold font-slab font-bold py-4 cursor-pointer' onClick={() => setOpen(!open)}>{props.data.title}</div>
                {open && <div className='text-xs leading-6 mb-4'>{props.data.desciption}</div>}
            </div>

            <SwitchInput value={consent} onChange={value => setConsent(value)} disabled={props.data.mandatory} />

        </div>
    );
}