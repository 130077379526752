
import { useState, useEffect } from 'react';


import Toast from 'elements/Toast';
import Breadcrumb from 'components/Breadcrumb';
// import NavigationLister from 'components/NavigationLister';
import ProductLister from 'components/ProductLister';
import TextSkeleton from 'elements/TextSkeleton';
import FavoriteButton from 'elements/FavoriteButton';

import { NBossCMSPage, getValueFromParameterMap } from 'cms/NBossCMS';
import { getTranslation } from 'utils/DictionaryUtils';
import { separateThousands } from 'utils/GenericUtils';
import { getGenderIcon } from 'utils/NeroliUtils';

import { useShop } from 'providers/ShopProvider';
import { useAuth } from 'providers/AuthProvider';

const getString = (arr) => { let str = ''; for (let i = 0; i < arr.length; i++) str += (i === 0 ? '' : ', ') + arr[i].parameterMap.name.value; return str; }

export default function ProductPage(props) {
    const [page, setPage] = useState(null);

    let _data = {
        product: getValueFromParameterMap(page, 'product'),
        productList: getValueFromParameterMap(page, 'productList') && getValueFromParameterMap(page, 'productList').value.contentList,
        breadcrumbs: getValueFromParameterMap(page, 'breadcrumbs'),
    }

    return (
        <NBossCMSPage pageprops={props} pageDataReady={page => { setPage(page) }} className='w-full flex flex-col site-main-gap'>

            <div className='flex flex-col gap-4'>

                <Breadcrumb data={_data.breadcrumbs} />

                <Product data={_data.product} />

            </div>

            {_data.productList && <ProductLister data={_data.productList} />}

            {/* <NavigationLister /> */}

        </NBossCMSPage>
    );
};

function Product(props) {
    const [variantSelected, setVariantSelected] = useState(null);

    let _data = {
        variantList: getValueFromParameterMap(props.data, 'variantList') && getValueFromParameterMap(props.data, 'variantList').contentList,
        family: getValueFromParameterMap(props.data, 'illatcsalad'),
        fejillat: getValueFromParameterMap(props.data, 'fejillat-osszetevok'),
        szivillat: getValueFromParameterMap(props.data, 'szivillat-osszetevok'),
        alapillat: getValueFromParameterMap(props.data, 'alapillat-osszetevok'),
        illatjegy: getValueFromParameterMap(props.data, 'illatjegy'),
    }

    useEffect(() => {
        if (!variantSelected && _data.variantList)
            setVariantSelected(_data.variantList[0]);
    }, [_data.variantList, variantSelected]);

    const hasFamily = () => {
        return _data.family && _data.family.length > 0 ? true : false;
    };

    const hasPyramid = () => {
        if ((_data.fejillat && _data.fejillat.length > 0) || (_data.szivillat && _data.szivillat.length > 0) || (_data.alapillat && _data.alapillat.length > 0))
            return true;
        return false;
    };

    return (
        <div className='site-content-grid site-main-gap'>

            <div className='col-span-2 lg:col-span-1 xl:col-span-2 flex flex-col site-main-gap'>

                <ProductImage data={props.data} />

                {(hasFamily() || hasPyramid()) && <div className='grid grid-cols-2 lg:grid-cols-1 xl:grid-cols-3 site-main-gap'>
                    {hasFamily() && <ProductFamily className='col-span-3' data={props.data} />}
                    {hasPyramid() && <ProductPyramid className='col-span-2 xl:grid-cols-3' data={props.data} />}
                </div>}

            </div>

            <div className='col-span-2 2xl:col-span-3 flex flex-col site-main-gap'>

                <ProductDescription data={props.data} />

                {/* {(hasFamily() || hasPyramid()) && <div className='site-content-sub-grid site-main-gap'>
                    {hasFamily() && <ProductFamily className='col-span-2' data={props.data} />}
                    {hasPyramid() && <ProductPyramid className='col-span-2' data={props.data} />}
                </div>} */}

                {props.data && <ProductOrder data={props.data} variantSelected={variantSelected} onVariantChanged={(variant) => setVariantSelected(variant)} />}

            </div>

        </div>
    );
}

function ProductImage(props) {
    const { isLoggedIn } = useAuth();

    let _data = {
        name: getValueFromParameterMap(props.data, 'name'),
        manufacturer: getValueFromParameterMap(props.data, 'gyarto') && getValueFromParameterMap(getValueFromParameterMap(props.data, 'gyarto'), 'name'),
        imageLarge: getValueFromParameterMap(props.data, 'nagykep') && getValueFromParameterMap(props.data, 'nagykep').downloadUrl,
    }

    return (
        <div className={'relative bg-gray-100 flex items-center justify-center aspect-square product-radial-gradient ' + (props.data ? '' : 'animate-pulse')}>

            {props.data && <img className='w-2/3' src={_data.imageLarge} alt={_data.name + ' - ' + _data.manufacturer} />}

            {isLoggedIn && <FavoriteButton className='absolute bottom-0 right-0 p-4 z-20' data={props.data} />}

        </div>
    );
}

function ProductDescription(props) {
    let _data = {
        name: getValueFromParameterMap(props.data, 'name'),
        manufacturer: getValueFromParameterMap(props.data, 'gyarto') && getValueFromParameterMap(getValueFromParameterMap(props.data, 'gyarto'), 'name'),
        description: getValueFromParameterMap(props.data, 'leiras'),
        activeIngredients: getValueFromParameterMap(props.data, 'aktiv-osszetevok'),
        advantages: getValueFromParameterMap(props.data, 'elonyok'),
        ingredientsUsage: getValueFromParameterMap(props.data, 'osszetevok-hasznalat'),
        notes: getValueFromParameterMap(props.data, 'illatjegy'),
        gender: getValueFromParameterMap(props.data, 'neme'),
    }

    return (
        <div className='flex flex-col site-main-gap'>

            {props.data ? <>

                <div className='flex flex-col items-start'>
                    <h2 className='pb-2 tracking-wider' dangerouslySetInnerHTML={{ __html: _data.manufacturer }} />
                    <h1 className='font-slab text-4xl text-gold font-bold border-t border-gold pt-2'>{_data.name}&nbsp;{getGenderIcon(_data.gender, 'inline-block')}</h1>
                </div>

                {_data.description && <div className='leading-7' dangerouslySetInnerHTML={{ __html: _data.description }} />}

                {_data.activeIngredients && <ProductTextDescriptionBlock title={getTranslation('active-ingredients')} content={_data.activeIngredients} />}
                {_data.advantages && <ProductTextDescriptionBlock title={getTranslation('advantages')} content={_data.advantages} />}
                {_data.ingredientsUsage && <ProductTextDescriptionBlock title={getTranslation('ingredients-usage')} content={_data.ingredientsUsage} />}
                {(_data.notes && _data.notes.length > 0) && <ProductTextDescriptionBlock title={getTranslation('scent-notes')} content={getString(_data.notes)} />}

            </> : <>

                <div className='flex flex-col items-start box-border'>
                    <TextSkeleton className='mb-2 w-[300px] h-6' />
                    <TextSkeleton className='mt-2 w-[380px] h-12' />
                </div>

                <TextSkeleton className='mb-2 w-full' lineCount={4} lineClassName='h-4' />

            </>}

        </div>
    );
}

function ProductTextDescriptionBlock(props) {
    return (
        <div className='flex flex-col gap-4'>

            <h2 className='heading'>{props.title}</h2>

            <div className='leading-7 capitalize' dangerouslySetInnerHTML={{ __html: props.content }} />

        </div>
    );
}

function ProductFamily(props) {
    let _data = {
        family: getValueFromParameterMap(props.data, 'illatcsalad'),
    }

    return (
        <div className={'flex flex-col gap-8 ' + (props.className || '')}>

            <h3 className='heading'>{getTranslation('scent-family')}</h3>

            {/* grid-cols-2 lg:grid-cols-1 xl:grid-cols-3 */}

            <div className='grid grid-cols-2 lg:grid-cols-1 xl:grid-cols-3 site-main-gap'>
                {_data.family && _data.family.map((item) => (<ProductFamilyItem key={item.id} data={item} />))}
            </div>

        </div>
    );
}

function ProductFamilyItem(props) {
    let _data = {
        name: getValueFromParameterMap(props.data, 'name'),
        description: getValueFromParameterMap(props.data, 'description'),
        perfumeSelection: getValueFromParameterMap(props.data, 'perfumeSelection'),
        image: getValueFromParameterMap(props.data, 'image') && getValueFromParameterMap(props.data, 'image').downloadUrl,
    }

    const getNameMain = () => {
        if (_data.name.indexOf(':') > -1)
            return _data.name.substring(0, _data.name.indexOf(':'))
        return null;
    };

    const getNameSub = () => {
        if (_data.name.indexOf(':') > -1)
            return _data.name.substring(_data.name.indexOf(':') + 1)
        return _data.name;
    };

    return (
        <div className='flex flex-col gap-2 justify-between'>

            <div className='flex-1 flex flex-col justify-center gap-1 text-center'>
                {getNameMain() && <div className='text-gold uppercase text-sm font-slab font-bold tracking-wider'>{getNameMain()}</div>}
                {getNameSub() && <div className='text-[10px] font-slab font-normal capitalize'>{getNameSub()}</div>}
            </div>

            <div className='aspect-square bg-gray-100 flex items-center justify-center'>

                {_data.image && <div className='w-auto max-w-[75%] shadow overflow-hidden'><img className='-p-[10px]' src={_data.image} alt={getNameMain()} /></div>}

            </div>

        </div>
    );
}

function ProductPyramid(props) {
    let _data = {
        fejillat: getValueFromParameterMap(props.data, 'fejillat-osszetevok'),
        szivillat: getValueFromParameterMap(props.data, 'szivillat-osszetevok'),
        alapillat: getValueFromParameterMap(props.data, 'alapillat-osszetevok'),
        illatjegy: getValueFromParameterMap(props.data, 'illatjegy'),
    }

    const containerClassName = 'flex-1 flex items-center justify-center';
    const stringClassName = 'text-[10px] font-normal font-slab drop-shadow-lg capitalize';

    return (
        <div className={'flex flex-col gap-8 justify-between ' + (props.className || '')}>

            <h3 className='heading'>{getTranslation('scent-pyramid')}</h3>

            <div className='flex flex-row gap-4 justify-between'>

                <div className='h-full flex flex-col self-stretch justify-around text-gold uppercase font-bold text-xs font-slab text-right'>
                    <div>{getTranslation('top-notes')}</div>
                    <div>{getTranslation('heart-notes')}</div>
                    <div>{getTranslation('base-notes')}</div>
                </div>

                <div className='relative flex-1 flex flex-col'>

                    <img className='w-full' src='/assets/images/artifact/pyramid.svg' alt='' />

                    <div className='absolute h-full w-full flex flex-col text-center'>
                        <div className={containerClassName}>{_data.fejillat && <div className={stringClassName}>{getString(_data.fejillat)}</div>}</div>
                        <div className={containerClassName}>{_data.szivillat && <div className={stringClassName}>{getString(_data.szivillat)}</div>}</div>
                        <div className={containerClassName}>{_data.alapillat && <div className={stringClassName}>{getString(_data.alapillat)}</div>}</div>
                    </div>

                </div>

            </div>

        </div>
    );
}

function ProductOrder(props) {
    const [toast, setToast] = useState(null);
    const [quantity, setQuantity] = useState(1);
    const { addToBasket } = useShop();

    let _data = {
        name: getValueFromParameterMap(props.data, 'name'),
        manufacturer: getValueFromParameterMap(props.data, 'gyarto') && getValueFromParameterMap(getValueFromParameterMap(props.data, 'gyarto'), 'name'),
        variantList: getValueFromParameterMap(props.data, 'variantList') && getValueFromParameterMap(props.data, 'variantList').contentList,
    }

    const addToCart = () => {
        addToBasket(props.data.key, props.variantSelected.key, quantity);
        setToast('<b>' + _data.manufacturer + ' - ' + _data.name + '</b> ' + getTranslation('added-to-cart'));
    };

    return (
        <div className='flex flex-col site-main-gap'>

            <div className='site-content-sub-grid site-main-gap'>

                <div className='col-span-3 border-b flex flex-row justify-between text-2xl font-bold font-slab py-2'>

                    {props.variantSelected && <div className=''>{getValueFromParameterMap(props.variantSelected, 'kiszereles')}</div>}
                    {props.variantSelected && <div>{separateThousands(getValueFromParameterMap(props.variantSelected, 'tenyleges-kosar-ar'))} <span className='text-sm'>Ft</span></div>}

                </div>

                <div className='flex flex-row items-center justify-between'>
                    <button className='font-bold text-gold'>HUF</button>
                    <div>/</div>
                    <button>EUR</button>
                </div>


            </div>

            {(_data.variantList && _data.variantList.length > 1) && <div className='site-content-sub-grid site-main-gap'>

                {_data.variantList.map((item) => (<ProductVariantItem key={item.id} data={item} onClick={() => props.onVariantChanged(item)} selected={props.variantSelected && (props.variantSelected.id === item.id)} />))}

            </div>}

            <div className='site-content-sub-grid site-main-gap'>

                <input className='border text-center font-bold p-4' type='number' min='1' max='99' value={quantity} onChange={e => setQuantity(e.target.value)} />
                <button className='button plain dark col-span-2 sm:col-span-3 2xl:col-span-2' onClick={() => addToCart()}>{getTranslation('add-to-cart')}</button>

            </div>

            <Toast message={toast} onHide={() => setToast(null)} />

        </div>
    );
}

function ProductVariantItem(props) {
    let _data = {
        packaging: getValueFromParameterMap(props.data, 'kiszereles'),
        oldPrice: getValueFromParameterMap(props.data, 'regi-ar'),
        price: getValueFromParameterMap(props.data, 'tenyleges-kosar-ar'),
        stock: getValueFromParameterMap(props.data, 'raktaron-levo-mennyiseg'),
        active: getValueFromParameterMap(props.data, 'megvasarolhato'),
        cantBeCounted: getValueFromParameterMap(props.data, 'megszamlalhatatlan'),
        contcentraion: getValueFromParameterMap(props.data, 'koncentracio'),
    }

    return (
        <button className={'aspect-square bg-gray-100 transition ' + (props.selected ? 'bg-gold text-white' : 'bg-gray-50')} onClick={() => props.onClick()}>

            <div className='text-xs pb-2'>{_data.packaging}</div>
            <div className='font-bold'>{separateThousands(_data.price)} <span className='text-xs'>Ft</span></div>

        </button>
    );
}