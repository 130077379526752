import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

import { TfiClose, TfiAlert, TfiCheck, TfiMinus } from 'react-icons/tfi';

export default function Modal(props) {
    return (
        <ModalBackground onBackgroundClick={() => props.onClose()}>

            <motion.div
                className={'w-full flex flex-col max-h-screen overflow-hidden site-main-px site-main-py ' + (props.className || '')}
                initial={{ opacity: 0, scale: 0 }}
                animate={{ opacity: 1, scale: 1 }}>

                <div className='flex flex-col flex-1 overflow-hidden black-gradient-opacity shadow-xl rounded'>
                    {props.children}
                </div>

            </motion.div>

        </ModalBackground>
    );
}

export function ModalBackground(props) {
    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className='fixed top-0 left-0 z-[1000] w-full h-full'>
            <div className='relative w-full h-full flex items-center justify-center'>
                <div className='absolute top-0 left-0 w-full h-full bg-opacity-20 bg-white backdrop-blur-md z-0' onClick={() => props.onBackgroundClick()} />
                <div className='z-10'>
                    {props.children}
                </div>
            </div>
        </motion.div>
    );
}

export function ModalHeader(props) {
    return (
        <div className='w-full flex flex-row gap-4 items-center p-6 sm:p-8'>
            <div className='flex-1'>{props.children}</div>
            {!props.disableClose && <button onClick={props.onClose ? props.onClose : null} className='p-2 -mr-2 group'>
                <TfiClose className='text-white group-hover:text-gold text-lg self-center transition' />
            </button>}
        </div >
    );
}

export function ModalContent(props) {
    return (
        <div className={'flex-1 line-scrollbar ml-6 sm:ml-8 mr-3 sm:mr-4 ' + (props.fixed ? '' : 'overflow-y-auto ') + (props.disableVerticalPadding ? '' : 'py-6 sm:py-8')}>
            <div className={'w-full h-full flex flex-col pr-3 sm:pr-4 ' + (props.className || '')}>
                {props.children}
            </div>
        </div>
    );
}

export function ModalButton(props) {
    const className = 'button ' + (props.secondary ? ' ' : 'plain ') + (props.className || '');

    if (props.to)
        return (<Link className={className} to={props.to} onClick={() => props.onClick ? props.onClick() : null} disabled={props.disabled}><ModalButtonContent {...props} /></Link>)
    else
        return (<button className={className} onClick={() => props.onClick()} disabled={props.disabled}><ModalButtonContent {...props} /></button>);
}

function ModalButtonContent(props) {
    return (
        <>
            {/* {props.icon && <DynamicIcon className={'text-sm'} iconName={props.icon} />} */}
            {props.label}
        </>
    );
};

export function ModalInput(props) {
    return (
        <div className='flex flex-col'>
            <input {...props} className={'outline-none bg-transparent border-b py-2 font-slab ' + (!props.error ? 'border-gold text-white placeholder:text-gold font-light' : 'border-red-800 text-red-800 placeholder:text-red-800')} placeholder={props.label} />
            {props.error && <ModalFeedback data={{ type: 'ERROR', message: props.error }} />}
        </div>
    );
}

// export function ModalInputForm(props) {
//     const { register, control } = useFormContext();

//     const currentValue = useWatch({ control, name: props.value, });

//     const className = 'w-full accent-gold outline-none bg-transparent border-b py-2 font-slab ' + (!props.error ? 'border-gold text-white placeholder:text-gold font-light' : 'border-red-800 text-red-800 placeholder:text-red-800');

//     const isHintVisible = () => {
//         if (props.type !== 'checkbox' && !props.optionList && currentValue !== null && currentValue !== '')
//             return true;
//         else if (props.optionList)
//             return true;
//         return false;
//     };

//     return (
//         <div className={'relative flex flex-col ' + (props.className || '')}>

//             <div className={'absolute -top-2 left-0 text-gold font-slab text-[9px] tracking-wider transition ' + (isHintVisible() ? 'opacity-100' : 'opacity-0')}>{props.label}</div>

//             {!props.optionList && <div className='flex flex-row items-center gap-4'>
//                 <input type={props.type} {...register(props.value)} className={className + (props.type === 'checkbox' ? 'h-8 w-auto' : '')} placeholder={props.label} id={props.value} />
//                 {props.type === 'checkbox' && <label className='cursor-pointer py-2 font-slab text-white flex-1' for={props.value}>{props.label}</label>}
//             </div>}

//             {props.optionList && <select {...register(props.value)} className={className}>{props.optionList}</select>}

//             {props.error && <ModalFeedback className='mt-2'><TfiAlert className='font-bold text-xs' />{props.error}</ModalFeedback>}

//         </div>
//     );
// }


export function ModalFeedback(props) {
    const errorClassName = 'bg-red-800';
    const warningClassName = 'bg-yellow-800';
    const successClassName = 'bg-green-800';

    const getTypeClassName = () => {
        if (props.data.type === 'WARN')
            return warningClassName;
        else if (props.data.type === 'SUCCESS')
            return successClassName;
        else
            return errorClassName;
    };

    const getIcon = () => {
        if (props.data.type === 'WARN')
            return <TfiMinus />;
        else if (props.data.type === 'SUCCESS')
            return <TfiCheck />;
        else
            return <TfiAlert />;
    };


    return (
        <div className={'text-white shadow-xl py-2 px-3 flex flex-row items-center justify-between gap-2 ' + getTypeClassName() + ' ' + (props.className || '')}>

            {(props.data && props.data.message) && <div className='tracking-wide text-opacity-90 font-light text-xs font-slab'>{props.data.message}</div>}

            {props.data && getIcon()}

        </div>
    );
};
