import React, { useState, useEffect } from 'react';
import { RiHeartLine, RiHeartFill, RiHeartAddLine } from 'react-icons/ri';

import Toast from 'elements/Toast';

import { getTranslation } from 'utils/DictionaryUtils';
import { getValueFromParameterMap } from 'cms/NBossCMS.js';

import { useShop } from 'providers/ShopProvider';

export default function FavoriteButton(props) {
    const [vote, setVote] = useState(0);
    const [toast, setToast] = useState(null);

    const { addToFavorites } = useShop();

    let _data = {
        name: getValueFromParameterMap(props.data, 'name'),
        manufacturer: getValueFromParameterMap(props.data, 'gyarto') && getValueFromParameterMap(getValueFromParameterMap(props.data, 'gyarto'), 'name'),
    }

    useEffect(() => {
        if (props.data && props.data.dataMap.vote)
            setVote(props.data.dataMap.vote.voteValue);
    }, [props.data])

    const doVote = (value) => {
        addToFavorites(props.data.dataMap.vote.typeKey, props.data.key, value);
        setToast('<b>' + _data.manufacturer + ' - ' + _data.name + '</b> ' + (value ? getTranslation('added-to-favorites') : getTranslation('removed-to-favorites')));
        setVote(value);
    };

    return (
        <>
            <button className={'group/favorite ' + (props.className || '')} onClick={() => doVote(vote === 0 ? 1 : 0)}>

                <div className='relative'>

                    <div className='absolute w-0 h-full left-0 group-hover/favorite:opacity-100 opacity-0 group-hover/favorite:translate-x-0 translate-x-2 transition'>
                        <div className='relative'>
                            <div className='absolute right-2 black-gradient-opacity rounded py-1 px-2 text-white text-[10px] tracking-wider font-slab whitespace-nowrap'>{vote ? getTranslation('remove-from-favorites') : getTranslation('add-to-favorites')}</div>
                        </div>
                    </div>

                    <div className='relative text-gold text-2xl'>
                        {vote ? <RiHeartFill className='opacity-100 group-hover/favorite:opacity-0 transition' /> : <RiHeartLine className='opacity-100 group-hover/favorite:opacity-0 transition' />}

                        <div className='absolute top-0 left-0'>
                            {vote ? <RiHeartLine className='opacity-0 group-hover/favorite:opacity-100 transition' /> : <RiHeartAddLine className='opacity-0 group-hover/favorite:opacity-100 transition' />}
                        </div>
                    </div>

                </div>

            </button>

            <Toast message={toast} onHide={() => setToast(null)} />

        </>
    );
}