import { useState, createContext, useContext, useEffect } from 'react';

import ShopBasketControl from 'services/ShopBasketControl';
import ShopGiftCardControl from 'services/ShopGiftCardControl';
import CmsVoteControl from 'services/CmsVoteControl';
import ShopCustomListControl from 'services/ShopCustomListControl';

import { useAuth } from 'providers/AuthProvider';

export const ShopContext = createContext(null);

export const useShop = () => {
    return useContext(ShopContext);
};

export default function ShopProvider(props) {
    const { isLoggedIn } = useAuth();

    useEffect(() => {
        updateBasket();
        updateFavorites();
    }, [isLoggedIn])

    const [basketContent, setBasketContent] = useState(false);
    const [basketItemCount, setBasketItemCount] = useState(0);

    const [favoritesContent, setFavoritesContent] = useState(false);
    const [favoritesItemCount, setFavoritesItemCount] = useState(0);

    useEffect(() => {
        updateBasket();
        updateFavorites();
    }, []);

    const updateBasket = () => {
        ShopBasketControl.getInfo().then(result => {
            setBasketContent(result);
            let itemCount = 0;
            if (result && result.itemList)
                result.itemList.forEach(d => { itemCount += d.quantity; });
            setBasketItemCount(itemCount);
        });
    };

    const updateFavorites = () => {
        ShopCustomListControl.getList({ favorite: true }, 0, 20).then(result => {
            setFavoritesContent(result);
            let itemCount = 0;
            if (result && result.contentList)
                itemCount = result.contentList.length;
            // result.contentList.forEach(d => { itemCount += d.quantity; });
            setFavoritesItemCount(itemCount);
        });
    };

    const addToBasket = (productKey, variantKey, quantity) => {
        ShopBasketControl.add(productKey, variantKey, quantity || 1).then(result => {
            updateBasket();
        });
    };

    const addGiftCardToBasket = (productKey, quantity) => {
        ShopGiftCardControl.addToBasket(productKey, quantity || 1).then(result => {
            updateBasket();
        });
    };

    const removeFromBasket = (productKey, variantKey) => {
        ShopBasketControl.remove(productKey, variantKey).then(result => {
            updateBasket();
        });
    };

    const setQuantity = (productKey, variantKey, quantity) => {
        ShopBasketControl.set(productKey, variantKey, quantity || 1).then(result => {
            updateBasket();
        });
    };

    const addToFavorites = (typeKey, key, vote) => {
        CmsVoteControl.vote(typeKey, key, vote).then(result => {
            updateFavorites();
        });
    };

    const value = {
        basketContent,
        basketItemCount,
        addToBasket,
        updateBasket,
        removeFromBasket,
        setQuantity,
        addToFavorites,
        updateFavorites,
        favoritesContent,
        favoritesItemCount,
        addGiftCardToBasket,
    };

    return (
        <ShopContext.Provider value={value}>
            {props.children}
        </ShopContext.Provider>
    );
}
