import React, { useState, useEffect } from 'react';

import ModalCookieConsent from 'elements/modal/ModalCookieConsent';

import globalConfig from 'config/globalConfig'

export default function CookieConsent(props) {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const cookieConsent = localStorage.getItem(globalConfig.etc.cookiePrefix + 'CookieConsent')
        let showPopup = cookieConsent ? false : true;
        if (showPopup)
            setTimeout(() => setVisible(true), 3000);
    }, [])

    return (<>{visible && <ModalCookieConsent onClose={() => { setVisible(false); localStorage.setItem(globalConfig.etc.cookiePrefix + 'CookieConsent', Date.now()); }} />}</>);
}