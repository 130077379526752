import dictionaryConfig from '../config/dictionaryConfig.js'
import { getWebsite } from 'utils/WebsiteUtils.js';

const _getTranslation = (lang, key, def) => {
    let translation = def ? def : ('[!' + key + '!]');
    if (dictionaryConfig[key] && dictionaryConfig[key][lang])
        translation = dictionaryConfig[key][lang];
    return translation;
};

const _getLanguage = () => {
    const map = { 'hu': 'hu-HU', 'en': 'en-GB', };
    return map[getWebsite().currentLocale.key];
};

export const getTranslation = (key) => {
    return _getTranslation(_getLanguage(), key, false);
};

export const getTranslationWithDefault = (key, def) => {
    return _getTranslation(_getLanguage(), key, def);
};

export const getTranslationForLanguageWithDefault = (lang, key, def) => {
    return _getTranslation(lang, key, def);
};

