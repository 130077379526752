import Lister, { ListerItem } from 'elements/Lister.js';

import { getValueFromParameterMap } from 'cms/NBossCMS.js';
import { getWebsite } from 'utils/WebsiteUtils';
import { getTranslation } from 'utils/DictionaryUtils';

export default function NavigationLister(props) {
    const list = (getWebsite().globalContent && getValueFromParameterMap(getWebsite().globalContent, 'navigation')) && getValueFromParameterMap(getWebsite().globalContent, 'navigation').parameterMap.itemList.value.contentList;

    return (
        <Lister title={!props.disableTitle && getTranslation('featured-pages')} preload={5}>

            {list && list.map((item) => (<NavigationItem key={item.key} data={item} />))}

        </Lister>
    );
}

function NavigationItem(props) {
    let _data = {
        label: getValueFromParameterMap(props.data, 'title'),
        image: getValueFromParameterMap(props.data, 'kep') && getValueFromParameterMap(props.data, 'kep').downloadUrl,
        externalUrl: getValueFromParameterMap(props.data, 'externalUrl'),
        internalUrl: getValueFromParameterMap(props.data, 'internalUrl'),
    }

    return (
        <ListerItem className='relative group overflow-hidden' to={_data.internalUrl} href={_data.externalUrl}>

            <div className={'absolute w-full h-full bg-center bg-no-repeat bg-cover ' + ((_data.internalUrl || _data.externalUrl) ? 'lister-item-image-group-hover' : 'grayscale')} style={{ backgroundImage: 'url(' + _data.image + ')' }}></div>

            <div className='lister-item-image-label'>{_data.label}</div>

        </ListerItem>
    );
}