import TextSkeleton from 'elements/TextSkeleton';

export default function PageHeading(props) {
    return (
        <div className={'flex-1 flex flex-row gap-4 site-content-grid site-main-gap ' + (props.className || '')}>

            <div className={'flex flex-col gap-4 order-2 lg:order-1 ' + (props.image ? 'lg:col-span-2 xl:col-span-3 2xl:col-span-4' : 'col-span-2 lg:col-span-3 xl:col-span-4 2xl:col-span-5')}>

                {!props.loading ? <>

                    {props.title && <h1 className='heading-large'>{props.title}</h1>}
                    {props.content && <div className='leading-7' dangerouslySetInnerHTML={{ __html: props.content }} />}

                </> : <>

                    <TextSkeleton className='w-full max-w-[380px] h-12' />
                    <TextSkeleton className='mb-2 w-full' lineCount={2} lineClassName='h-4' />

                </>}

            </div>

            {props.image && <img className='w-full grayscale opacity-75 order-1 lg:order-2' src={props.image} alt={props.title} />}

        </div>
    );
}
