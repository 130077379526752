import React from 'react';
import { Link } from 'react-router-dom';

import About from 'components/About';
import NavigationLister from 'components/NavigationLister';
import InstagramLister from 'components/InstagramLister';

import { NBossCMSPage } from 'cms/NBossCMS';

export default function Error404Page(props) {
    return (
        <NBossCMSPage pageprops={props} className='w-full flex flex-col site-main-gap'>

            <div className='grid site-content-grid site-main-gap 2xl:pb-4'>
                <ErrorMessage className='col-span-4 aspect-square sm:aspect-video lg:aspect-[5/2]' />
                <About className='col-span-4 2xl:col-span-1' />
            </div>

            <NavigationLister />

            <InstagramLister />

        </NBossCMSPage>
    );
}

function ErrorMessage(props) {
    return (
        <div className={'relative bg-gray-100 flex items-center flex-col lg:flex-row justify-center lg:gap-8 p-4 ' + (props.className || '')} >

            <div className='absolute w-full h-full bg-center bg-no-repeat bg-cover bg-opacity-5 grayscale opacity-[0.025]' style={{ backgroundImage: 'url(' + 'https://images.unsplash.com/photo-1526374870839-e155464bb9b2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80' + ')' }}></div>

            <h1 className='text-[128px] text-gold font-cursive z-10'>404</h1>

            <div className='flex flex-col gap-4 pb-4 z-10 text-center lg:text-left'>
                <h2 className='text-3xl font-slab'>A keresett oldal nem található.</h2>
                <div className='text-sm leading-7'>
                    <div className='inline sm:block'>Elnézést kérünk, ez az oldal jelenleg nem elérhető vagy nem létezik. </div>
                    <div className='inline sm:block'>Kérjük, ellenőrizze a címet vagy látogasson el <Link to='/' className='text-gold'>főoldalunkra</Link>.</div>
                </div>
            </div>

        </div>
    );
}