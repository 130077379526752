export default function TextSkeleton(props) {
    const alternatingLineLenghts = ['w-[95%]', 'w-[90%]', 'w-full'];

    if (props.lineCount)
        return (<div className='w-full flex flex-col gap-4'>

            {Array.from(Array(props.lineCount)).map((item, index) => (<Line key={index} className={props.lineClassName + ' ' + (index === (props.lineCount - 1) ? 'w-2/3' : alternatingLineLenghts[Math.floor(Math.random() * alternatingLineLenghts.length)])} />))}

        </div>)
    else
        return (
            <Line className={props.className} />
        );
}

function Line(props) {
    return (
        <div className={'bg-gray-100 rounded animate-pulse ' + (props.className || '')} />
    );
}

