
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import Lister, { ListerItem } from 'elements/Lister';
import FavoriteButton from 'elements/FavoriteButton';
import PageMessage from 'elements/PageMessage';

import { getValueFromParameterMap } from 'cms/NBossCMS';

import CmsFrontendControl from 'services/CmsFrontendControl';
import ShopCustomListControl from 'services/ShopCustomListControl';

import { useAuth } from 'providers/AuthProvider';
import { getTranslation } from 'utils/DictionaryUtils';

const block = 20;

export default function ProductLister(props) {
    const [list, setList] = useState(null);
    const [listData, setListData] = useState(null);
    // const [count, setCount] = useState(null);

    const [listKey, setListKey] = useState(null);
    const [customQuery, setCustomQuery] = useState(null);

    useEffect(() => {
        if (props.list) { setList(props.list); }
    }, [props.list]);

    useEffect(() => {
        if (listKey || customQuery) { getItems(); }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listKey, customQuery]);

    useEffect(() => {
        reset();

        if (props.listKey) { setListKey(props.listKey); }
        if (props.customQuery) { setCustomQuery(props.customQuery); }
    }, [props.listKey, props.customQuery]);

    useEffect(() => { if (props.onListChanged) props.onListChanged(list); }, [list, props]);
    useEffect(() => { if (props.onListDataChanged) props.onListDataChanged(listData); }, [listData, props]);

    const reset = () => {
        setList(null);
        setListData(null);
        // setCount(null);
        setListKey(null);
        setCustomQuery(null);
    };

    const getItems = (_length) => {
        const first = list ? (list.length) : 0;
        const last = _length ? _length : block;

        if (customQuery) {
            ShopCustomListControl.getList(customQuery, first, last).then(result => { _setList(result); });
        } else if (listKey) {
            CmsFrontendControl.getList(listKey, first, last).then(result => { _setList(result); });
        }
    };

    const _setList = (result) => {
        setListData(result);
        // setCount(result.count);

        setList(list ? list.concat(result.contentList) : result.contentList);
    };

    return (
        <div className='flex flex-col site-main-gap'>

            {(listData && listData.count === 0) ? <PageMessage title={getTranslation('empty-product-category-title')} content={getTranslation('empty-product-category-content')} /> : <Lister title={props.title} preload={props.preload || 10}>

                {list && list.map((item) => (<ProductListerItem key={item.id} data={item} />))}

            </Lister>}

            {(listData && (list.length < listData.count)) ? <button className='button plain dark' onClick={() => getItems()}>{getTranslation('load-more')}</button> : null}

        </div>
    );
}

function ProductListerItem(props) {
    const { isLoggedIn } = useAuth();

    let _data = {
        name: getValueFromParameterMap(props.data, 'name'),
        manufacturer: getValueFromParameterMap(props.data, 'gyarto') && getValueFromParameterMap(getValueFromParameterMap(props.data, 'gyarto'), 'name'),
        description: getValueFromParameterMap(props.data, 'lista-leiras'),
        image: getValueFromParameterMap(props.data, 'listakep') && getValueFromParameterMap(props.data, 'listakep').downloadUrl,
        url: getValueFromParameterMap(props.data, 'url'),
        new: getValueFromParameterMap(props.data, 'ujdonsag'),
        treasure: getValueFromParameterMap(props.data, 'kincsesbanya'),
    }

    return (
        <ListerItem className='relative aspect-auto flex flex-col bg-transparent group'/*to={_data.url}*/>

            {_data.new && <div className={'absolute -top-4 black-gradient rounded-full w-14 aspect-square z-10 flex items-center justify-center ' + (_data.treasure ? '-left-4' : '-right-4')}>
                <div className='font-slab text-white text-sm uppercase leading-none'>{getTranslation('new')}</div>
            </div>}

            {_data.treasure && <div className='absolute -top-[2.75%] right-0 w-[30%] z-10 '>
                <img className='w-full' src='/assets/images/artifact/treasure.svg' alt='' />
                <div className='absolute w-full h-full top-0 left-0 flex items-center justify-center'>
                    <div className='pl-[10%] pb-[25%] font-slab text-center text-white text-[10px] uppercase z-10 leading-tight'>{getTranslation('treasure-chest')}</div>
                </div>
            </div>}

            <div className='w-full relative overflow-hidden'>
                <Link to={_data.url}>
                    <div className='relative w-full flex items-center justify-center aspect-square bg-gray-100 product-radial-gradient lister-item-image-group-hover'>
                        <img className='w-2/3' src={_data.image} alt={_data.name + ' - ' + _data.manufacturer} />
                    </div>
                    <div className='absolute top-0 left-0 w-full h-full flex items-center justify-center z-10'>
                        <div className='w-2/3 absolute button plain dark z-10 group-hover:scale-100 scale-105 opacity-0 group-hover:opacity-95 transition duration-medium'>{getTranslation('show-product')}</div>
                    </div>
                </Link>
                {isLoggedIn && <FavoriteButton className='absolute bottom-0 right-0 p-2 z-20' data={props.data} />}
            </div>

            <Link className='w-full flex flex-col pt-2' to={_data.url}>
                <div className='text-xs tracking-wider pb-1 border-b border-gold' dangerouslySetInnerHTML={{ __html: _data.manufacturer }} />
                <div className='font-slab text-lg text-gold font-bold pt-1'>{_data.name}</div>
                {_data.description && <div className='text-xs pt-1' dangerouslySetInnerHTML={{ __html: _data.description }} />}
            </Link>

        </ListerItem>
    );
}