
import { useState } from 'react';

import Breadcrumb from 'components/Breadcrumb';
import ProductLister from 'components/ProductLister';
import NavigationLister from 'components/NavigationLister';
import PageHeading from 'elements/PageHeading';
import PageMessage from 'elements/PageMessage';

import { NBossCMSPage, getValueFromParameterMap } from 'cms/NBossCMS';

import { useAuth } from 'providers/AuthProvider';
import { getTranslation } from 'utils/DictionaryUtils';

export default function ProductListPage(props) {
    const [page, setPage] = useState(null);

    const { isLoggedIn } = useAuth();

    let _data = {
        query: getValueFromParameterMap(page, 'query'),
        title: getValueFromParameterMap(page, 'query') ? getValueFromParameterMap(page, 'title') : (getValueFromParameterMap(page, 'topContent') && getValueFromParameterMap(getValueFromParameterMap(page, 'topContent'), 'name')),
        content: getValueFromParameterMap(page, 'query') ? getValueFromParameterMap(page, 'description') : (getValueFromParameterMap(page, 'topContent') && getValueFromParameterMap(getValueFromParameterMap(page, 'topContent'), 'leiras')),
        image: getValueFromParameterMap(page, 'topContent') && (getValueFromParameterMap(getValueFromParameterMap(page, 'topContent'), 'kep-A4Zle') && getValueFromParameterMap(getValueFromParameterMap(page, 'topContent'), 'kep-A4Zle').downloadUrl),
        forLoggedIn: getValueFromParameterMap(page, 'for-logged-in'),
        productList: getValueFromParameterMap(page, 'productList'),
        breadcrumbs: getValueFromParameterMap(page, 'breadcrumbs'),
    }

    const getCustomQuery = () => {
        if (_data.query) {
            let query = {};
            query[_data.query] = true;
            return query;
        }
        return null;
    };

    return (
        <NBossCMSPage pageprops={props} pageDataReady={page => { setPage(page) }} className='w-full flex flex-col site-main-gap'>

            <div className='flex flex-col gap-4'>

                <Breadcrumb data={_data.breadcrumbs} />

                <PageHeading loading={_data.title ? false : true} title={_data.title} content={_data.content} image={_data.image} />

            </div>

            {(!_data.forLoggedIn || (_data.forLoggedIn && isLoggedIn)) ? <ProductLister listKey={_data.productList && _data.productList.key} customQuery={getCustomQuery()} forLoggedIn={_data.forLoggedIn} /> : <PageMessage title={getTranslation('page-for-logged-in-only-title')} content={getTranslation('page-for-logged-in-only-content')} />}

            <NavigationLister />

        </NBossCMSPage>
    );
};