import nBossConnection from 'services/nBossConnection';

class ShopCustomerAccessManagement {
	constructor() {
		this.connection = nBossConnection.connection;
		this.CONTROL = 'shopCustomerAccessManagement';
	}

	/**
	 * @return {Promise<Object>}
	 */
	getLoggedInClient() {
		return new Promise((resolve, reject) => {
			this.connection
				.get(`/${this.CONTROL}/getLoggedInClient`)
				.then((response) => resolve(response.data))
				.catch(reject);
		});
	}
}

export default new ShopCustomerAccessManagement();
