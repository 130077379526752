import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { Link, useLocation } from 'react-router-dom';
import { TfiClose } from 'react-icons/tfi';

import Menu from 'components/Menu';
import { getTranslation } from 'utils/DictionaryUtils';
import { FootNote } from 'components/Footer';
import LanguageSelector from 'components/LanguageSelector';

import { useAuth } from 'providers/AuthProvider';
import { useShop } from 'providers/ShopProvider';

export default function MobileMenu(props) {
    const { isLoggedIn } = useAuth();
    const { basketItemCount } = useShop();
    
    let location = useLocation();

    useEffect(() => {
        if (props.onSliderClose())
            props.onSliderClose()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    return (
        <motion.div
            className={'w-full h-full flex flex-col items-start gap-8 black-gradient p-8 z-50 ' + (props.className || '')}
            initial={props.open ? 'visible' : 'hidden'}
            animate={props.open ? 'visible' : 'hidden'}
            variants={{ visible: { opacity: 1, scale: 1, display: 'flex' }, hidden: { opacity: 0, scale: 1.025, transitionEnd: { display: 'none' } } }}>

            <div className='w-full flex flex-row justify-between items-center'>

                <Link to='/' className='h-[70px]'>
                    <img className='h-full' alt='logo' src='/assets/images/logo/full-gold-gradient.svg' />
                </Link>

                <button onClick={() => props.onSliderClose()}><TfiClose className='text-white group-hover:text-gold text-2xl' /></button>

            </div>

            <div className='flex-1 w-full overflow-y-auto line-scrollbar'>
                <Menu light />
            </div>

            <div className='w-full flex flex-col gap-4 justify-between'>
                <button className='button' onClick={() => { props.onSliderClose(); !isLoggedIn ? props.onLogin() : props.onProfile(); }}>{!isLoggedIn ? getTranslation('login') : getTranslation('my-profile')}</button>
                <button className='button' onClick={() => { props.onSliderClose(); props.onCart(); }}>{getTranslation('cart')} <span className='text-white'>({basketItemCount})</span></button>
            </div>

            <div className='w-full flex flex-row justify-center divide-x opacity-100'>

                <FootNote className='px-2'>2005-{new Date().getFullYear()} © NEROLI parfüméria</FootNote>
                <FootNote className='px-2'> <a className='transition hover:text-gold' href='https://creo.hu/' target='_blank' rel='noreferrer'>Creo Group</a></FootNote>

                <LanguageSelector text itemClassName='text-white uppercase text-xs leading-none px-2' />

            </div>

        </motion.div >
    );
}
