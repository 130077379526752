const globalConfig = {
    'domainName': 'neroli.hu',
    'etc': {
        'cookiePrefix': 'NEROLI_',
    },
    'search': {
        'path': 'kereses',
        'queryParam': 'kifejezes',
    },
}

export default globalConfig;