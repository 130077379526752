import { useState, createContext, useContext, useEffect } from 'react';
import ShopCustomerAccessManagement from 'services/ShopCustomerAccessManagement';
import ShopCustomerLogin from 'services/ShopCustomerLogin';

export const AuthContext = createContext(null);

export const useAuth = () => {
	return useContext(AuthContext);
};

export default function UserProvider(props) {
	const [authChecked, setAuthChecked] = useState(false);
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [userData, setUserData] = useState(null);

	useEffect(() => {
		ShopCustomerAccessManagement.getLoggedInClient().then((response) => {
			setUserData(response);
			setIsLoggedIn(response !== null);
			setAuthChecked(true);
		});
	}, []);

	const handleLogin = () => {
		ShopCustomerAccessManagement.getLoggedInClient().then((response) => {
			setUserData(response);
			setIsLoggedIn(response !== null);
		});
	};

	const handleLogout = () => {
		ShopCustomerLogin.logout().then(() => {
			setIsLoggedIn(false);
			setUserData(null);
		});
	};

	const value = {
		authChecked,
		userData,
		isLoggedIn,
		handleLogin,
		handleLogout,
	};

	return (
		<AuthContext.Provider value={value}>
			{authChecked ? props.children : null}
		</AuthContext.Provider>
	);
}
