import { useAuth } from 'providers/AuthProvider';

import Modal, { ModalButton, ModalHeader, ModalContent } from 'elements/modal/Modal';
import Cart from 'components/Cart';

import { getTranslation } from 'utils/DictionaryUtils.js';
import { useShop } from 'providers/ShopProvider';

export default function ModalCart(props) {
	const { userData } = useAuth();
	const { basketItemCount } = useShop();

	return (
		<Modal className='' {...props}>

			<ModalHeader {...props}>

				<div className='heading'>{getTranslation('cart-content')}</div>

				{basketItemCount > 0 && <div className='text-white text-[13px] font-slab pt-2'>
					{getTranslation('cart-sub-title').replace('USERNAME', (userData ? userData.userName : getTranslation('user'))).replace('PRODUCT', (basketItemCount > 1 ? getTranslation('products-alt').toLowerCase() : getTranslation('product-alt').toLowerCase()))}
				</div>}

			</ModalHeader>

			<ModalContent disableVerticalPadding>

				<div className='flex flex-col gap-4'>

					{basketItemCount ? <>

						<Cart
							cartItemHeaderClassName='text-[9px] text-white'
							cartItemColClassName='text-white text-xs md:text-sm'
							cartItemColHighlightClassName='text-sm'
							cartDiscountItemClassName='bg-black shadow-xl text-white text-xs'
							cartSummaryItemClassName='text-white text-xs py-4 md:py-8 lg:aspect-square' />

					</> : <>

						<div className='text-sm font-slab text-white'>{getTranslation('empty-cart-message')}</div>

					</>}



				</div>


			</ModalContent>

			<ModalContent fixed className='w-full flex flex-col sm:flex-row gap-4'>
				{basketItemCount < 1 && <ModalButton className='flex-1' label={getTranslation('countinue-shopping')} onClick={() => props.onClose()} />}
				{basketItemCount > 0 && <ModalButton className='flex-1' label={getTranslation('place-order')} to='/megrendeles' onClick={() => props.onClose()} />}
			</ModalContent>

		</Modal>
	);
}