import { Link } from 'react-router-dom';

import Newsletter from 'components/Newsletter';
import SocialLister from 'components/SocialLister';

import { getValueFromParameterMap } from 'cms/NBossCMS.js';
import { getWebsite } from 'utils/WebsiteUtils.js';
import { getTranslation } from 'utils/DictionaryUtils';

export default function Footer(props) {
    let _data = {
        company: getValueFromParameterMap(getWebsite().footerContent, 'company'),
        customer: getValueFromParameterMap(getWebsite().footerContent, 'customer'),
        information: getValueFromParameterMap(getWebsite().footerContent, 'information'),
    };

    return (
        <footer className={'flex flex-col items-center black-gradient site-main-padding pt-16 pb-8 gap-4 sm:gap-16 site-main-px ' + (props.className || '')}>

            <div className='w-full site-footer-grid site-main-gap'>

                <div className='flex sm:hidden md:flex lg:hidden xl:flex justify-start sm:justify-center'>
                    <img className='hidden sm:block h-[100px]' alt='search' src='/assets/images/logo/initial-gold-gradient.svg' />
                    <img className='block sm:hidden h-[70px] mb-6' alt='search' src='/assets/images/logo/full-gold-gradient.svg' />
                </div>

                <Block title={getTranslation('the-company')}>
                    <Lister>
                        {_data.company && _data.company.contentList.map((item, index) => <ListItem key={item.id} data={item} />)}
                    </Lister>
                </Block>

                <Block title={getTranslation('the-customer')}>
                    <Lister>
                        {_data.customer && _data.customer.contentList.map((item, index) => <ListItem key={item.id} data={item} />)}
                    </Lister>
                </Block>

                <Block className='hidden md:flex lg:hidden' />

                <Block title={getTranslation('information')}>
                    <Lister>
                        {_data.information && _data.information.contentList.map((item, index) => <ListItem key={item.id} data={item} />)}
                    </Lister>
                </Block>

                <Block title={getTranslation('newsletter')}>
                    <Newsletter className='flex flex-col gap-4' inputClassName='h-10' buttonClassName='' />
                    <SocialLister className='flex-col' titleClassName='heading' itemClassName='text-white hover:text-gold' />
                </Block>

                <div></div>

            </div>

            <div className='w-full flex flex-col gap-2 sm:gap-0 sm:flex-row justify-center lg:justify-end sm:divide-x'>

                <FootNote className='sm:px-2 sm:first:pl-0 sm:last:pr-0'>2005-{new Date().getFullYear()} © NEROLI parfüméria</FootNote>
                <FootNote className='sm:px-2 sm:first:pl-0 sm:last:pr-0'>Developed by <a className='transition hover:text-gold' href='https://creo.hu/' target='_blank' rel='noreferrer'>Creo Group</a></FootNote>

            </div>

        </footer>
    );
}

function Block(props) {
    return (
        <div className={'flex flex-col gap-8 ' + (props.className || '')}>
            {props.title && <h3 className='heading'>{props.title}</h3>}
            {props.children && <div className='flex flex-col gap-8'>{props.children}</div>}
        </div>
    );
}

function Lister(props) {
    return (
        <div className='flex flex-col gap-4'>
            {props.children}
        </div>
    );
}

function ListItem(props) {
    let _data = {
        label: getValueFromParameterMap(props.data, 'label'),
        internalUrl: getValueFromParameterMap(props.data, 'internalUrl'),
        externalUrl: getValueFromParameterMap(props.data, 'externalUrl'),
        documentDownload: getValueFromParameterMap(props.data, 'documentDownload') && getValueFromParameterMap(props.data, 'documentDownload').downloadUrl,
    };

    const className = 'text-white text-sm font-light tracking-wide transition ' + ((_data.internalUrl || _data.externalUrl || _data.documentDownload) ? 'hover:text-gold' : 'opacity-50');

    if (_data.internalUrl) {
        return <Link to={_data.internalUrl} className={className}>{_data.label}</Link>
    } else {
        return (<a href={_data.externalUrl || _data.documentDownload} target='_blank' rel='noreferrer' className={className}>{_data.label}</a>);
    }
}

export function FootNote(props) {
    return (
        <div className={'text-white uppercase text-xs leading-none sm:px-2 sm:first:pl-0 sm:last:pr-0 ' + (props.className || '')}>
            {props.children}
        </div>
    );
}