import nBossConnection from 'services/nBossConnection.js';

class ShopCustomerControl {
    constructor() {
        this.connection = nBossConnection.connection;
        this.CONTROL = 'shopCustomerControl';
    }

    /**
    * @param {dto} dto 
    */
    save(dto) {
        return new Promise((resolve, reject) => {
            this.connection.post(`/${this.CONTROL}/save`, { dto }).then(response => resolve(response.data)).catch(reject);
        });
    }

    /**
    * @param {dto} dto 
    */
    update(dto) {
        return new Promise((resolve, reject) => {
            this.connection.post(`/${this.CONTROL}/update`, { dto }).then(response => resolve(response.data)).catch(reject);
        });
    }

    getCountrySelection() {
        return new Promise((resolve, reject) => {
            this.connection.post(`/${this.CONTROL}/getCountrySelection`, {}).then(response => resolve(response.data)).catch(reject);
        });
    }

    getPaymentMethodSelection() {
        return new Promise((resolve, reject) => {
            this.connection.post(`/${this.CONTROL}/getPaymentMethodSelection`, {}).then(response => resolve(response.data)).catch(reject);
        });
    }

    /**
    * @param {email} email 
    */
    requestNewPassword(email) {
        return new Promise((resolve, reject) => {
            this.connection.post(`/${this.CONTROL}/requestNewPassword`, { email }).then(response => resolve(response.data)).catch(reject);
        });
    }

    /**
    * @param {key} key 
    * @param {userId} userId 
    * @param {dto} dto 
    */
    setNewPassword(userId, key, dto) {
        return new Promise((resolve, reject) => {
            this.connection.post(`/${this.CONTROL}/setNewPassword`, { userId, key, dto }).then(response => resolve(response.data)).catch(reject);
        });
    }

}

export default new ShopCustomerControl();
