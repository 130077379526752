import { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { getTranslation } from 'utils/DictionaryUtils';
import { getSearchUrl } from 'utils/GenericUtils';

import CmsFrontendControl from 'services/CmsFrontendControl';

export default function Search(props) {
    const [term, setTerm] = useState('');
    const [focus, setFocus] = useState(false);
    const [result, setResult] = useState([]);

    const location = useLocation();
    const navigate = useNavigate();

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && result && result.length > 0)
            navigate(result[0].url);
    }

    const handleBlur = (event) => {
        if (event.relatedTarget) {
            const timer = setTimeout(() => { setFocus(false); }, 1000);
            return () => clearTimeout(timer);
        } else {
            setFocus(false);
        }
    }

    useEffect(() => {
        if (focus && term && term !== '')
            search();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [term, focus]);

    useEffect(() => {
        setResult([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    const search = (event) => { CmsFrontendControl.search(term).then(result => { setResult(result) }); }

    return (
        <div className={'relative transition-all ' + (props.className || '')}>

            <input
                className={'w-full bg-transparent border-b border-zinc-500 text-lg font-light outline-none placeholder:text-black placeholder:uppercase ' + (props.right ? 'text-right' : 'text-left')}
                placeholder={getTranslation('search')}
                value={term}
                onChange={e => setTerm(e.target.value)}
                onKeyDown={handleKeyDown}
                onFocus={() => setFocus(true)}
                onBlur={(e) => handleBlur(e)}></input>

            {focus && <div className='absolute w-full bg-white/95 shadow-xl max-h-40 overflow-y-scroll flex flex-col transition'>
                {result.map((item) => <Item key={item.url} data={item} right={props.right} />)}
            </div>}

        </div>
    );
}

function Item(props) {
    return (
        <Link to={props.data.url} className='min-h-8 text-xs px-3 py-2 flex items-center justify-between hover:bg-gold hover:text-white transition'>
            <div className={'font-slab font-semibold tracking-wider' + (props.right ? 'order-2' : 'order-1')} dangerouslySetInnerHTML={{ __html: props.data.name }} />
            <div className={'text-[10px] opacity-20' + (props.right ? 'order-1' : 'order-2')} dangerouslySetInnerHTML={{ __html: props.data.itemType }} />
        </Link>
    );
}