import { Link } from 'react-router-dom';

export default function Lister(props) {
    return (
        <div className='flex flex-col site-main-gap'>

            {props.title && <h2 className={props.headingClassName ? props.headingClassName : 'heading-large'}>{props.title}</h2>}

            {(props.children || props.preload) && <div className={'grid site-content-grid site-main-gap items-start ' + (props.className || '')}>

                {props.children}

                {(!props.children && props.preload) && Array.from(Array(props.preload)).map((item, index) => (<ListerItem key={index} />))}

            </div>}

        </div>
    );
}

export function ListerItem(props) {
    const className = 'aspect-square bg-gray-100 flex items-center justify-center ' + (props.children ? '' : 'animate-pulse ') + (props.className || '');

    if (props.to && !props.newWindow) {
        return (<Link className={className} to={props.to}>{props.children}</Link>);
    } else if (props.href || props.to) {
        return (<a href={props.href || props.to} target={props.newWindow ? '_blank' : '_self'} className={className} rel='noreferrer'>{props.children}</a>);
    } else if (props.onClick) {
        return (<button className={className} onClick={() => props.onClick()}>{props.children}</button>);
    } else {
        return (<div className={className}>{props.children}</div>);
    }
}