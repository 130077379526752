import nBossConnection from 'services/nBossConnection';

class ShopCustomerLogin {
	constructor() {
		this.connection = nBossConnection.connection;
		this.CONTROL = 'shopCustomerLogin';
	}

	/**
	 * @param {string} userName
	 * @param {password} password
	 * @return {Promise<boolean>}
	 */
	login(userName, password) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/login`, { userName, password })
				.then((response) => resolve(response.data))
				.catch(reject);
		});
	}

	logout() {
		return new Promise((resolve, reject) => {
			this.connection
				.get(`/${this.CONTROL}/logout`)
				.then((response) => resolve(response.data))
				.catch(reject);
		});
	}
}

export default new ShopCustomerLogin();
