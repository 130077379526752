let website = null;

export const setWebsite = (data) => {
    website = data;
};

export const getWebsite = () => {
    return website;
};

export const getPageKeyByPathName = (pathName) => {
    return website.sitemap[pathName] ? website.sitemap[pathName].pageKey : null;
};

export const getPathNameByPageKey = (pageKey) => {
    for (let key in website.sitemap)
        if (website.sitemap[key].pageKey === pageKey)
            return key;
    return null;
};