import React, { useState, useEffect } from 'react';
import { GoogleApiWrapper, Map, Marker } from 'google-maps-react';

import mapConfig from 'config/mapConfig.js';

export function AddressMap(props) {
    const [center, setCenter] = useState({ lat: props.lat, lng: props.lng });

    // console.log(props.lat + '-' + props.lng);

    useEffect(() => {
        setCenter({ lat: props.lat, lng: props.lng });
    }, [props.lat, props.lng]);

    const _mapLoaded = (mapProps, map) => {
        map.setOptions({ styles: mapConfig.styles });
    }

    const onMarkerClick = () => {
        if (props.mapUrl)
            window.open(props.mapUrl, '_blank');
        // console.log(props.mapUrl);
    }

    return (
        <Map style={{ width: '100%', height: '256px' }} google={props.google}
            center={center}
            initialCenter={center}
            zoom={15}
            disableDefaultUI={true}
            onReady={(mapProps, map) => _mapLoaded(mapProps, map)}>

            <Marker
                position={{ lat: center.lat, lng: center.lng }}
                onClick={() => onMarkerClick()}
                name={props.name}
                icon={{
                    url: (mapConfig.markerMap['default']),
                    anchor: new props.google.maps.Point(40, 40),
                    scaledSize: new props.google.maps.Size(40, 40)
                }}></Marker>

        </Map>
    );
};

const LoadingContainer = (props) => (
    <div className='w-full h-64 flex items-center justify-center bg-gray-100 text-sm font-bold text-gray-100 tracking-wider uppercase'>Térkép előkészítése...</div>
)

export default GoogleApiWrapper({ apiKey: 'AIzaSyD1luTKqk_RQjga3FZ2vTeFJhms3nDrjSg', language: 'hu-HU', LoadingContainer: LoadingContainer })(AddressMap);