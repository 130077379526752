import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import MobileMenu from 'components/MobileMenu.js';
import LanguageSelector from 'components/LanguageSelector.js';
import SearchInput from 'components/SearchInput.js';

import ModalCart from 'elements/modal/ModalCart.js';
import ModalAuth from 'elements/modal/ModalAuth.js';
import ModalProfile from 'elements/modal/ModalProfile.js';

import { getTranslation } from 'utils/DictionaryUtils';
import { EventRegister } from 'utils/EventRegister.js';
import { useAuth } from 'providers/AuthProvider';
import { useShop } from 'providers/ShopProvider';

export default function Header(props) {
    const { isLoggedIn } = useAuth();

    const [compact, setCompact] = useState(0);

    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    const [cartModalVisible, setCartModalVisible] = useState(false);

    const [authModalVisible, setAuthModalVisible] = useState(false);
    const [authModalContentType, setAuthModalContentType] = useState('LOGIN');

    const [profileModalVisible, setProfileModalVisible] = useState(false);

    useEffect(() => {
        const onScroll = () => { setCompact(window.pageYOffset > 20); };

        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    useEffect(() => {
        let popupAuthEvent = EventRegister.addEventListener('POPUP_AUTH', (data) => { setAuthModalContentType(data.type); setAuthModalVisible(true); });
        let profileAuthEvent = EventRegister.addEventListener('POPUP_PROFILE', (data) => { setProfileModalVisible(true); });
        return () => {
            EventRegister.removeEventListener(popupAuthEvent);
            EventRegister.removeEventListener(profileAuthEvent);
        }
    }, []);

    return (
        <>
            <header className={'w-full sticky top-0 h-32 pointer-events-none ' + (props.className || '')}>

                <div className={'w-full site-main-px transition-all pointer-events-auto site-main-grid site-main-gap ' + (compact ? 'h-16 backdrop-blur-md bg-white/30 shadow-[0_35px_60px_-15px_rgba(0,0,0,0.075)] py-1.5' : 'h-32 py-5')}>

                    <Block className='hidden md:flex justify-end'>

                        <SearchInput className='hidden md:block 2xl:hidden ' right />

                    </Block>

                    <Block className='hidden xl:flex'></Block>

                    <Block className='sm:col-span-1 lg:col-span-2 xl:col-span-1 2xl:col-span-2 justify-between md:justify-center'>

                        <Link to='/'><img className={'transition-all ' + (compact ? 'h-[35px]' : 'h-[70px]')} alt='search' src={!compact ? '/assets/images/logo/full-gold-gradient.svg' : '/assets/images/logo/full-gold-gradient.svg'} /></Link>

                        <div className='flex md:hidden'><Hamburger onClick={() => setMobileMenuOpen(!mobileMenuOpen)} /></div>

                    </Block>

                    <Block className='hidden xl:flex'>

                        <SearchInput className='hidden 2xl:block' />

                    </Block>

                    <Block className='hidden md:flex justify-between transition-none'>

                        <ButtonBlock className='flex xl:flex' onProfileClick={() => { setProfileModalVisible(!profileModalVisible) }} onLoginClick={() => { setAuthModalContentType('LOGIN'); setAuthModalVisible(!authModalVisible); }} />

                        <div className='flex flex-row items-center gap-4'>
                            {isLoggedIn && <FavoritesIndicator onClick={() => setCartModalVisible(!cartModalVisible)} />}
                            <CartIndicator onClick={() => setCartModalVisible(!cartModalVisible)} />
                        </div>

                    </Block>

                </div>

            </header>

            <MobileMenu
                className='fixed flex md:hidden'
                open={mobileMenuOpen}
                onSliderClose={() => setMobileMenuOpen(false)}
                onLogin={() => setAuthModalVisible(true)}
                onProfile={() => setProfileModalVisible(true)}
                onCart={() => setCartModalVisible(true)} />

            {authModalVisible && (<ModalAuth onClose={() => setAuthModalVisible(false)} contentType={authModalContentType} onContentTypeChanged={(contentType) => setAuthModalContentType(contentType)} />)}
            {profileModalVisible && (<ModalProfile onClose={() => setProfileModalVisible(false)} />)}
            {cartModalVisible && (<ModalCart onClose={() => setCartModalVisible(false)} />)}

        </>
    );
}

function ButtonBlock(props) {
    const { isLoggedIn } = useAuth();

    return (
        <>
            {isLoggedIn ? (<Button className={props.className} label={getTranslation('my-profile')} onClick={() => props.onProfileClick()} />) : (<Button label={getTranslation('login')} onClick={() => props.onLoginClick()} />)}
            <LanguageSelector className={props.className} itemClassName='text-lg font-light hover:text-gold transition tracking-wide uppercase' />
        </>
    );
}

function Block(props) {
    return (
        <div className={'flex items-center ' + (props.className || '')}>
            {props.children}
        </div>
    );
}

function Hamburger(props) {
    return (
        <button className={'w-8 flex flex-col justify-between items-center aspect-video ' + (props.className || '')} onClick={props.onClick}>
            <div className='w-full h-[1px] bg-gold' />
            <div className='w-full h-[1px] bg-gold' />
            <div className='w-full h-[1px] bg-gold' />
        </button>
    );
}

function Button(props) {
    return (
        <button className={'text-lg font-light hover:text-gold transition tracking-wide uppercase ' + (props.className || '')} onClick={() => props.onClick()}>
            {props.label}
        </button>
    );
}

function CartIndicator(props) {
    const { basketItemCount } = useShop();

    return (
        <button className='relative w-[28px] h-[28px]' onClick={() => props.onClick()}>

            <div className={'absolute top-0 w-[28px] h-[28px] border transition ' + (basketItemCount ? 'border-gold bg-gold' : 'border-zinc-500 ')} />

            <div className='absolute overflow-hidden -top-[7px] left-[8px] w-[12px] h-[6px]'>
                <div className={'border border-zinc-500 rounded-full w-[12px] h-[12px] transition ' + (basketItemCount ? 'border-gold' : 'border-zinc-500 ')} />
            </div>

            <div className='absolute top-0 w-[28px] h-[28px] flex items-center justify-center'>
                <div className={'text-sm leading-none ' + (basketItemCount ? 'text-white ' : 'text-zinc-500 ')}>{basketItemCount}</div>
            </div>

        </button>
    );
}

function FavoritesIndicator(props) {
    const { favoritesItemCount } = useShop();

    return (
        <Link className='relative w-[32px] h-[32px]' to={'kedvencek'}>

            <img className='h-[32px]' alt='favorites' src={favoritesItemCount ? '/assets/images/icon/heart-fill.svg' : '/assets/images/icon/heart-outline.svg'} />

            <div className='absolute -top-[1px] w-[32px] h-[32px] flex items-center justify-center'>
                <div className={'text-sm leading-none ' + (favoritesItemCount ? 'text-white ' : 'text-zinc-500 ')}>{favoritesItemCount}</div>
            </div>

        </Link>
    );
}
