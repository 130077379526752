import nBossConnection from 'services/nBossConnection.js';

class ShopCustomListControl {
    constructor() {
        this.connection = nBossConnection.connection;
        this.CONTROL = 'shopCustomListControl';
    }

    /**
     * @param {key} key
     * @param {first} first
     * @param {limit} limit
     */
    getList(query, first, limit) {
        return new Promise((resolve, reject) => {
            this.connection.post(`/${this.CONTROL}/getList`, { query, first, limit }).then(response => resolve(response.data)).catch(reject);
        });
    }

}

export default new ShopCustomListControl();
