
import { useState, useEffect } from 'react';

import { NBossCMSPage } from 'cms/NBossCMS.js';

export default function ContentPage(props) {
    const [page, setPage] = useState(null);

    useEffect(() => {
    }, [page]);

    // let _data = {
    //     heading: getValueFromParameterMap(page, 'heading'),
    // }

    return (
        <NBossCMSPage pageprops={props} pageDataReady={page => { setPage(page) }} className='w-full flex flex-col site-main-gap'>


        </NBossCMSPage>
    );
};