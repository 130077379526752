import SocialLister from 'components/SocialLister';

import { getWebsite } from 'utils/WebsiteUtils.js';
import { getValueFromParameterMap } from 'cms/NBossCMS.js';

export default function About(props) {
    return (
        <div className={'bg-gray-100 h-full flex flex-col p-4 ' + (props.maxHeight ? 'max-h-[560px] ' : '') + (props.className || '')}>

            <div className='flex flex-col leading-none'>
                <div className='font-slab font-light text-4xl uppercase'>Neroli</div>
                <div className='font-slab font-light text-lg text-gold'>Est. 2005</div>
            </div>

            <div className='flex-1'>
                <div className='h-[1px] w-[32px] bg-gold my-2' />
                <div className='text-sm 2xl:max-[1564px]:text-xs leading-7 2xl:max-[1564px]:leading-6' dangerouslySetInnerHTML={{ __html: getValueFromParameterMap(getWebsite().globalContent, 'short-introduction') }} />
            </div>

            <SocialLister className='flex-row items-center mt-4' titleClassName='heading-small' itemClassName='hover:text-gold' />

        </div>
    );
}