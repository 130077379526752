const dictionaryConfig = {
    'login': {
        'hu-HU': 'Belépés',
        'en-GB': 'Login'
    },
    'logout': {
        'hu-HU': 'Kijelentkezés',
        'en-GB': 'Logout'
    },
    'registration': {
        'hu-HU': 'Regisztráció',
        'en-GB': 'Registration'
    },
    'forgot-password': {
        'hu-HU': 'Elfelejtett jelszó',
        'en-GB': 'Forgot password'
    },
    'new-password': {
        'hu-HU': 'Új jelszó',
        'en-GB': 'New password'
    },
    'did-you-forget-your-password': {
        'hu-HU': 'Elfelejtette jelszavát?',
        'en-GB': 'Did you forget your password?'
    },
    'back': {
        'hu-HU': 'Vissza',
        'en-GB': 'Back'
    },
    'back-to-login': {
        'hu-HU': 'Vissza a belépéshez',
        'en-GB': 'Back to login'
    },
    'login-content': {
        'hu-HU': 'Amennyiben már regisztrált a NEROLI oldalára, felhasználóneve és jelszava segítségével tud belépni.',
        'en-GB': 'If you have already registered on the NEROLI site, you can log in using your username and password.'
    },
    'registration-content': {
        'hu-HU': 'Az alábbi adatok kitöltésével egyszerűen regisztrálhat a NEROLI oldalára.',
        'en-GB': 'You can easily register on the NEROLI site by filling in the information below.'
    },
    'registration-feedback-content': {
        'hu-HU': 'Sikeresen regisztrált a NEROLI oldalára. A regisztráció során megadott adatokkal tud mostantól belépni az oldalra.',
        'en-GB': 'You have successfully registered on the NEROLI website. You can now access the site using the data you provided during registration.'
    },
    'forgot-password-content': {
        'hu-HU': 'Az alábbi adat megadásával visszaállíthatja elfelejtett jelszavát.',
        'en-GB': 'You can reset your forgotten password by entering the information below.'
    },
    'new-password-content': {
        'hu-HU': 'Kérjük, adjon meg új jelszót a felhasználói fiókja számára.',
        'en-GB': 'Please enter a new password for your account.'
    },
    'forgot-password-feedback-content': {
        'hu-HU': 'A megadott adatok alapján e-mail címre éretsítés küldtünk, melyben tájékoztatjuk a további teendőkről.',
        'en-GB': 'Based on the data provided, we have sent a notification to the e-mail address, in which we will inform you about further actions.'
    },
    'new-password-feedback-content': {
        'hu-HU': 'Az új jelszó beállítása sikeres volt. Mostantól az újonnan megadott jelszava, ás felhasználóneve segítségével beléphet az oldalra.',
        'en-GB': 'The new password was successfully set. From now on, you can enter the site using your newly entered password and username.'
    },
    'email-address': {
        'hu-HU': 'E-mail cím',
        'en-GB': 'E-mail address'
    },
    'phone-number': {
        'hu-HU': 'Telefonszám',
        'en-GB': 'Phone number'
    },
    'user-name': {
        'hu-HU': 'Felhasználónév',
        'en-GB': 'User name'
    },
    'password': {
        'hu-HU': 'Jelszó',
        'en-GB': 'Password'
    },
    'password-again': {
        'hu-HU': 'Jelszó ismét',
        'en-GB': 'Password again'
    },
    'send': {
        'hu-HU': 'Küldés',
        'en-GB': 'Send'
    },
    'my-profile': {
        'hu-HU': 'Profil',
        'en-GB': 'Profile'
    },
    'profile-data': {
        'hu-HU': 'Felhasználói adatok',
        'en-GB': 'Profile settings'
    },
    'cart': {
        'hu-HU': 'Kosár',
        'en-GB': 'Cart'
    },
    'search': {
        'hu-HU': 'Keresés',
        'en-GB': 'Search'
    },
    'please-check-data': {
        'hu-HU': 'Kérjük, ellenőrizze a megadott adatot.',
        'en-GB': 'Please check the data provided.'
    },
    'billing-data': {
        'hu-HU': 'Számlázási adatok',
        'en-GB': 'Billing data'
    },
    'shipping-data': {
        'hu-HU': 'Szállítási adatok',
        'en-GB': 'Shipping data'
    },
    'personal-data': {
        'hu-HU': 'Személyes adatok',
        'en-GB': 'Personal data'
    },
    'additional-data': {
        'hu-HU': 'További adatok',
        'en-GB': 'Additional data'
    },
    'name': {
        'hu-HU': 'Név',
        'en-GB': 'Name'
    },
    'country': {
        'hu-HU': 'Ország',
        'en-GB': 'Country'
    },
    'zip-code': {
        'hu-HU': 'Irányítószám',
        'en-GB': 'Zip code'
    },
    'state': {
        'hu-HU': 'Megye',
        'en-GB': 'State/County'
    },
    'city': {
        'hu-HU': 'Település',
        'en-GB': 'City'
    },
    'address': {
        'hu-HU': 'Cím',
        'en-GB': 'Address'
    },
    'company-name': {
        'hu-HU': 'Cég név',
        'en-GB': 'Company name'
    },
    'tax-number': {
        'hu-HU': 'Adószám',
        'en-GB': 'Tax number'
    },
    'billing-is-company': {
        'hu-HU': 'Cégként vásárolok',
        'en-GB': 'I buy as a company'
    },
    'birthday': {
        'hu-HU': 'Születésnap',
        'en-GB': 'Birthday'
    },
    'comment': {
        'hu-HU': 'Megjegyzés',
        'en-GB': 'Comment'
    },
    'the-company': {
        'hu-HU': 'A Cég',
        'en-GB': 'The Company'
    },
    'the-customer': {
        'hu-HU': 'A Vásárló',
        'en-GB': 'The Customer'
    },
    'information': {
        'hu-HU': 'Információ',
        'en-GB': 'Information'
    },
    'newsletter': {
        'hu-HU': 'Hírlevél',
        'en-GB': 'Newsletter'
    },
    'subscribe-newsletter': {
        'hu-HU': 'Hírlevél feliratkozás',
        'en-GB': 'Newsletter subscription'
    },
    'subscribe-newsletter-success': {
        'hu-HU': 'Köszönjük, hogy feliratkozott a NEROLI elektronikus hírlevelére.',
        'en-GB': 'Thank you for subscribing to NEROLI electronic newsletter.'
    },
    'subscribe-newsletter-success-content': {
        'hu-HU': 'Ön ezzel az elsők között, kényelmesen értesülhet: Újdonságainkról, időszakos kedvezményekről és akcióinkról. Ha bármikor úgy dönt, hogy nem kívánja tovább igénybe venni szolgáltatásunkat, leiratkozhat a hírlevélről. Ha a későbbiekben más e-mail címre kéri a hírlevelet, azt is könnyedén megváltoztathatja.',
        'en-GB': 'You can be one of the first to conveniently find out about:<br/><br/><ul><li>Our news</li><li>Periodical discounts and promotions</li></ul><br/><br/ >If you decide at any time that you no longer wish to use our service, you can unsubscribe from the newsletter. If you later request the newsletter to a different e-mail address, you can also easily change it.'
    },
    'subscribe': {
        'hu-HU': 'Feliratkozás',
        'en-GB': 'Subscribe'
    },
    'payment-method': {
        'hu-HU': 'Fizetési mód',
        'en-GB': 'Payment method'
    },
    'save-data': {
        'hu-HU': 'Adatok mentése',
        'en-GB': 'Save changes'
    },
    'new-products': {
        'hu-HU': 'Újdonságok',
        'en-GB': 'New products'
    },
    'featured-pages': {
        'hu-HU': 'Kiemelt oldalaink',
        'en-GB': 'Our featured pages'
    },
    'our-shops': {
        'hu-HU': 'Üzleteink',
        'en-GB': 'Our shops'
    },
    'follow-us': {
        'hu-HU': 'Kövessen minket',
        'en-GB': 'Follow us'
    },
    'follow-us-instagram': {
        'hu-HU': 'Kövessen minket Instagramon',
        'en-GB': 'Follow us on Instagram'
    },
    'please-select': {
        'hu-HU': 'Kérjük, válasszon',
        'en-GB': 'Please, select'
    },
    'scent-pyramid': {
        'hu-HU': 'Illatpiramis',
        'en-GB': 'Scent pyramid'
    },
    'scent-family': {
        'hu-HU': 'Illatcsalád',
        'en-GB': 'Scent family'
    },
    'top-notes': {
        'hu-HU': 'Fejillat',
        'en-GB': 'Top notes'
    },
    'heart-notes': {
        'hu-HU': 'Szívillat',
        'en-GB': 'Heart notes'
    },
    'base-notes': {
        'hu-HU': 'Alapillat',
        'en-GB': 'Base notes'
    },
    'scent-notes': {
        'hu-HU': 'Illatjegyek',
        'en-GB': 'Scent notes'
    },
    'add-to-cart': {
        'hu-HU': 'Kosárba rakom',
        'en-GB': 'Add to cart'
    },
    'new': {
        'hu-HU': 'Új',
        'en-GB': 'New'
    },
    'treasurechest': {
        'hu-HU': 'Kincsesbánya',
        'en-GB': 'Treasurechest'
    },
    'treasure-chest': {
        'hu-HU': 'Kincses-bánya',
        'en-GB': 'Treasure-chest'
    },
    'show-product': {
        'hu-HU': 'Megnézem',
        'en-GB': 'Show'
    },
    'cart-sub-title': {
        'hu-HU': 'Kedves USERNAME, kosara az alábbi PRODUCT tartalmazza',
        'en-GB': 'Dear USERNAME, your cart contains the following PRODUCT'
    },
    'product': {
        'hu-HU': 'Termék',
        'en-GB': 'Product'
    },
    'product-alt': {
        'hu-HU': 'Terméket',
        'en-GB': 'Product'
    },
    'products': {
        'hu-HU': 'Termékek',
        'en-GB': 'Products'
    },
    'products-alt': {
        'hu-HU': 'Termékeket',
        'en-GB': 'Products'
    },
    'user': {
        'hu-HU': 'Felhasználó',
        'en-GB': 'User'
    },
    'packaging': {
        'hu-HU': 'Kiszerelés',
        'en-GB': 'Packaging'
    },
    'price': {
        'hu-HU': 'Ár',
        'en-GB': 'Price'
    },
    'pieces': {
        'hu-HU': 'Darab',
        'en-GB': 'Pieces'
    },
    'total': {
        'hu-HU': 'Összesen',
        'en-GB': 'Total'
    },
    'discount': {
        'hu-HU': 'Kedvezmény',
        'en-GB': 'Discount'
    },
    'discounts': {
        'hu-HU': 'Kedvezmények',
        'en-GB': 'Discounts'
    },
    'shipping': {
        'hu-HU': 'Szállítás',
        'en-GB': 'Shipping'
    },
    'place-order': {
        'hu-HU': 'Megrendelem',
        'en-GB': 'Place the order'
    },
    'order': {
        'hu-HU': 'Megrendelés',
        'en-GB': 'Order'
    },
    'countinue-shopping': {
        'hu-HU': 'Vásárlás folytatása',
        'en-GB': 'Countinue shopping'
    },
    'empty-cart-message': {
        'hu-HU': 'Kosara üres, jelenleg nem tartalmaz terméket.',
        'en-GB': 'Your cart is empty and currently contains no products.'
    },
    'cart-content': {
        'hu-HU': 'Kosár tartalma',
        'en-GB': 'Cart content'
    },
    'customer-data': {
        'hu-HU': 'Vevőadatok',
        'en-GB': 'Customer data'
    },
    'data': {
        'hu-HU': 'Adatok',
        'en-GB': 'Data'
    },
    'overview': {
        'hu-HU': 'Áttekintés',
        'en-GB': 'Review'
    },
    'done': {
        'hu-HU': 'Kész',
        'en-GB': 'Done'
    },
    'contacts': {
        'hu-HU': 'Elérhetőségek',
        'en-GB': 'Contacts'
    },
    'added-to-cart': {
        'hu-HU': 'kosárhoz adva',
        'en-GB': 'added to cart'
    },
    'active-ingredients': {
        'hu-HU': 'Aktív összetevők',
        'en-GB': 'Active ingredients'
    },
    'advantages': {
        'hu-HU': 'Előnyök',
        'en-GB': 'Advantages'
    },
    'ingredients-usage': {
        'hu-HU': 'Összetevők használata',
        'en-GB': 'Ingredients usage'
    },
    'add-to-favorites': {
        'hu-HU': 'Kedvencekhez adom',
        'en-GB': 'Add to favorites'
    },
    'added-to-favorites': {
        'hu-HU': 'kedvencekhez adva',
        'en-GB': 'added to favorites'
    },
    'remove-from-favorites': {
        'hu-HU': 'Eltávolítás a kedvencekből',
        'en-GB': 'Remove from favorites'
    },
    'removed-to-favorites': {
        'hu-HU': 'eltávolítva a kedvencekből',
        'en-GB': 'removed from favorites'
    },
    'profile-success-feedback': {
        'hu-HU': 'A profil adatai sikeresen mentésre kerültek',
        'en-GB': 'Your profile data has been saved successfully'
    },
    'profile-error-feedback': {
        'hu-HU': 'Hiba a profil mentése során',
        'en-GB': 'Error while saving profile'
    },
    'load-more': {
        'hu-HU': 'Továbbiak betöltése',
        'en-GB': 'Load more'
    },
    'page-for-logged-in-only-title': {
        'hu-HU': 'Az oldalt kizárólag belépett felhasználó tekintheti meg',
        'en-GB': 'The page can only be viewed by logged in users'
    },
    'page-for-logged-in-only-content': {
        'hu-HU': 'Amennyiben már rendelkezik felhasználói fiókkal a NEROLI oldalára, kérjük, jelentkezzen be, amennyiben még nem rendelkezik fiókkal, kérjük, regisztráljon, a folyamat csupán pár percet vesz igénybe.',
        'en-GB': 'If you already have a user account on the NEROLI site, please log in, if you do not yet have an account, please register, the registration process only takes a few minutes.'
    },
    'empty-product-category-title': {
        'hu-HU': 'Nem található termék',
        'en-GB': 'No product found'
    },
    'empty-product-category-content': {
        'hu-HU': 'Sajnáljuk, nem találunk megjeleníthető terméket a keresett kategóriában. Kérjük, próbálja meg keresését más kategóriában, vagy vegye fel velünk a kapcsolatot elérhetőségeinken.',
        'en-GB': 'We are sorry, but we can not find any displayable products in the category you are looking for. Please try your search in another category or contact us via our contact details.'
    },
    'continue-to-overview': {
        'hu-HU': 'Tovább az áttekintésre',
        'en-GB': 'Continue to review'
    },
    'for-those-who-have-already-registered': {
        'hu-HU': 'Azoknak, akik már regisztráltak a NEROLI weboldalán.',
        'en-GB': 'For those who have already registered on the NEROLI website.'
    },
    'shop-first-time': {
        'hu-HU': 'Most vásárolna először a NEROLI online butikjában? Ehhez csupán a Regisztrálok gombra kell kattintania, majd megadnia a szükséges adatokat.',
        'en-GB': 'Would you like to shop in NEROLI online boutique for the first time? To do this, you just need to click on the Register button and then enter the necessary data.'
    },
    'shop-as-guest': {
        'hu-HU': 'Vendégként vásárolok',
        'en-GB': 'I am shopping as a guest'
    },
    'countinue-as-guest': {
        'hu-HU': 'Tovább vendégként',
        'en-GB': 'Continue as guest'
    },
    'possible-as-guest': {
        'hu-HU': 'Oldalunkon regisztráció nélkül is lehetséges a vendégként, ilyen esetben csak a szállításhoz elengedhetetlen adatok megadása szükséges.',
        'en-GB': 'It is also possible to register as a guest on our website, in which case you only need to enter data essential for the delivery.'
    },
    'logged-in-user': {
        'hu-HU': 'Belépett felhasználó',
        'en-GB': 'Logged in user'
    },
    'already-logged-in-as': {
        'hu-HU': 'Oldalunkra már sikeresen bejelentkezett a(z) <strong>USERNAME</strong> felhasználónevű fiókja segítségével.',
        'en-GB': 'You have already successfully logged in to our site using your <strong>USERNAME</strong> account.'
    },
    'keep-profile-up-to-date': {
        'hu-HU': 'Az egyszerű, és gyors megrendelés érdekében, kérjük, tartsa naprakészen profil adatait. A profilban megadott adatoktól eltérő adatokkal történő vásárláshoz a következő lépésben módosíthatja a szükséges információkat.',
        'en-GB': 'In order to order easily and quickly, please keep your profile information up to date. To make a purchase with data other than the data entered in the profile, you can change the necessary information in the next step.'
    },
    'continue-to-data': {
        'hu-HU': 'Tovább az adatokra',
        'en-GB': 'Continue to data'
    },
    'place-the-order': {
        'hu-HU': 'Leadom a rendelést',
        'en-GB': 'Place the order'
    },
    'thanks-for-choosing-neroli': {
        'hu-HU': 'Köszönjük, hogy a NEROLI PARFÜMÖT választotta!',
        'en-GB': 'Thank you for choosing NEROLI PERFUME!'
    },
    'successful-order': {
        'hu-HU': 'Sikeresen megrendelte a terméket.',
        'en-GB': 'You have successfully ordered the product.'
    },
    'payment-countdown': {
        'hu-HU': 'A megrendelés befejezéséhez TIME átirányítjuk a fizetési felületre.',
        'en-GB': 'To complete the order, in TIME we will redirect you to the payment page.'
    },
    'in-seconds': {
        'hu-HU': 'másodpercen belül',
        'en-GB': 'seconds'
    },
    'now': {
        'hu-HU': 'most',
        'en-GB': 'now'
    },
    'visit': {
        'hu-HU': 'Megtekintés',
        'en-GB': 'Visit'
    },
    'continue-to-payment': {
        'hu-HU': 'Tovább az fizetésre',
        'en-GB': 'Continue to payment'
    },
    'the-order-id': {
        'hu-HU': 'A megrendelés azonosítója',
        'en-GB': 'The id of your order'
    },
    'the-tansaction-id': {
        'hu-HU': 'A tranzakció azonosítója',
        'en-GB': 'The id of the transaction'
    },
    'back-to-main-page': {
        'hu-HU': 'Vissza a főoldalra',
        'en-GB': 'Back to main page'
    },
    'processing-of-payment': {
        'hu-HU': 'Fizetési adatok feldolgozása.',
        'en-GB': 'Processing of payment data.'
    },
    'process-takes-a-few-seconds': {
        'hu-HU': 'A folyamat néhány másodpercet vesz igénybe.',
        'en-GB': 'This process takes a few seconds.'
    },
    'tansaction-successful': {
        'hu-HU': 'A tranzakciós folyamat sikeresen lezárult.',
        'en-GB': 'The transaction process has been completed successfully.'
    },
    'tansaction-unsuccessful': {
        'hu-HU': 'A tranzakciós folyamat sikertelenül zárult.',
        'en-GB': 'The transaction process has been completed unsuccessfully.'
    },
    'payment-feedback-info': {
        'hu-HU': 'Kérjük, amennyiben a rendelése felől információt szeretne kérni, vagy változtatni szeretne megrendelésén, erre az azonsítóra hivatkozzon.<br />A megrendeléssel kapcsolatos további információkat e-mail-ben továbbítottuk.',
        'en-GB': 'Please refer to this identifier if you would like to request information about your order or change your order.<br />Further information about the order has been sent by e-mail.'
    },
    'payment-feedback-contact': {
        'hu-HU': 'Amennyiben kérdése, észrevétele lenne, keressen minket bizalommal az alábbi elérhetőségeken:',
        'en-GB': 'If you have any questions or comments, feel free to contact us at the following addresses:'
    },
}

export default dictionaryConfig;