import CmsFrontendControl from 'services/CmsFrontendControl';
import { useLocation } from 'react-router-dom';

import { getWebsite, getPageKeyByPathName, getPathNameByPageKey } from 'utils/WebsiteUtils';
import globalConfig from 'config/globalConfig'

export default function LanguageSelector(props) {
    const availableLocales = getWebsite().availableLocales;
    const currentLocale = getWebsite().currentLocale;

    const location = useLocation();

    const onLangChange = (key) => {
        // console.log(getPageKeyByPathName(location.pathname));
        CmsFrontendControl.setLocale(globalConfig.domainName, key, getPageKeyByPathName(location.pathname)).then(result => { result && window.location.reload() });
    };

    return (
        <div className={'flex flex-row ' + (props.className || '')}>

            {availableLocales && availableLocales.map((item) => (item.key !== currentLocale.key &&
                (props.text ? <TextLanguageItem className={props.itemClassName} key={item.id} data={item} onClick={() => onLangChange(item.key)} /> :
                    <FlagLanguageItem className={props.itemClassName} key={item.id} data={item} onClick={() => onLangChange(item.key)} />)
            ))}

        </div>
    );
}

function TextLanguageItem(props) {
    return (
        <button className={' ' + (props.className || '')} onClick={() => props.onClick()}>
            {props.data.key}
        </button>
    );
}

function FlagLanguageItem(props) {
    return (
        <button className={'grayscale transition hover:grayscale-0 opacity-60 hover:opacity-80 ' + (props.className || '')} onClick={() => props.onClick()}>
            <img className='w-[34px]' alt={props.data.title} src={'/assets/images/language/' + props.data.key + '.png'} />
        </button>
    );
}