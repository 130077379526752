import nBossConnection from 'services/nBossConnection.js';

class ShopOrderControl {
    constructor() {
        this.connection = nBossConnection.connection;
        this.CONTROL = 'shopOrderControl';
    }

    getCustomer(key) {
        return new Promise((resolve, reject) => {
            this.connection.post(`/${this.CONTROL}/getCustomer`, {}).then(response => resolve(response.data)).catch(reject);
        });
    }

    /**
    * @param {dto} dto 
    */
    setupCustomer(dto) {
        return new Promise((resolve, reject) => {
            this.connection.post(`/${this.CONTROL}/setupCustomer`, { dto }).then(response => resolve(response.data)).catch(reject);
        });
    }

    order() {
        return new Promise((resolve, reject) => {
            this.connection.post(`/${this.CONTROL}/order`, {}).then(response => resolve(response.data)).catch(reject);
        });
    }

    /**
    * @param {r} r 
    * @param {s} s 
    */
    paymentBack(r, s) {
        return new Promise((resolve, reject) => {
            this.connection.post(`/${this.CONTROL}/paymentBack`, { r, s }).then(response => resolve(response.data)).catch(reject);
        });
    }

}

export default new ShopOrderControl();
