
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Slider from 'components/Slider.js';
import About from 'components/About.js';
import NavigationLister from 'components/NavigationLister';
import InstagramLister from 'components/InstagramLister.js';
import ProductLister from 'components/ProductLister.js';

import { EventRegister } from 'utils/EventRegister';
import { NBossCMSPage, getValueFromParameterMap } from 'cms/NBossCMS.js';
import { getTranslation } from 'utils/DictionaryUtils';
import { getQueryVariable } from 'utils/GenericUtils';

export default function MainPage(props) {
    const [page, setPage] = useState(null);

    let location = useLocation();

    let _data = {
        slider: getValueFromParameterMap(page, 'slider'),
        newProductList: getValueFromParameterMap(page, 'newProductList'),
    }

    useEffect(() => {
        let id = getQueryVariable(location.search, 'id');
        let key = getQueryVariable(location.search, 'key');
        if (id && key)
            EventRegister.emit('POPUP_AUTH', { type: 'NEW_PASSWORD' })
    }, [location]);

    return (
        <NBossCMSPage pageprops={props} pageDataReady={page => { setPage(page) }} className='w-full flex flex-col site-main-gap'>

            <div className='grid site-content-grid site-main-gap 2xl:pb-4'>
                <Slider className='col-span-4' data={_data.slider} />
                <About className='col-span-4 2xl:col-span-1' />
            </div>

            <NavigationLister disableTitle />

            <ProductLister title={getTranslation('new-products')} list={_data.newProductList && _data.newProductList.contentList} />

            <InstagramLister />

        </NBossCMSPage>
    );
};