
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, useLocation } from 'react-router-dom';
import { TfiEmail, TfiMobile } from 'react-icons/tfi';

import Cart from 'components/Cart';
import Profile from 'components/Profile';
import { DataLine } from 'pages/ContactPage';

import { NBossCMSPage, getValueFromParameterMap } from 'cms/NBossCMS';
import { getQueryVariable } from 'utils/GenericUtils';
import { getWebsite } from 'utils/WebsiteUtils';
import { getTranslation } from 'utils/DictionaryUtils';
import { useAuth } from 'providers/AuthProvider';
import { scrollTop } from 'utils/ScrollToTop';
import { EventRegister } from 'utils/EventRegister';
import { useShop } from 'providers/ShopProvider';

import ShopOrderControl from 'services/ShopOrderControl';

export default function OrderPage(props) {
    const [page, setPage] = useState(null);

    const [contentType, setContentType] = useState('CART');

    const [result, setResult] = useState(null);
    const [paymentResult, setPaymentResult] = useState(null);

    let location = useLocation();

    useEffect(() => {
    }, [page]);

    useEffect(() => {
        if (location && location.search) {
            let r = getQueryVariable(location.search, 'r');
            let s = getQueryVariable(location.search, 's');
            if (r && s) {
                setContentType('PAYMENT_LOADING');
                ShopOrderControl.paymentBack(r, s).then(result => { setPaymentResult(result); });
            }
        }
    }, [location]);

    useEffect(() => { scrollTop(); }, [contentType]);

    useEffect(() => { if (result !== null) setContentType('FEEDBACK') }, [result]);

    useEffect(() => { if (paymentResult !== null) setContentType('PAYMENT_FEEDBACK') }, [paymentResult]);

    return (
        <NBossCMSPage pageprops={props} pageDataReady={page => { setPage(page) }} className='w-full flex flex-col site-main-gap'>

            <h1 className='heading-large'>{getTranslation('order')}</h1>

            <StepList onContentTypeChanged={contentType => setContentType(contentType)} current={contentType} />

            <div>

                {contentType === 'CART' && <CartContent onNext={() => setContentType('AUTH')} />}
                {contentType === 'AUTH' && <AuthContent onNext={() => setContentType('CUSTOMER_DATA')} onPrev={() => setContentType('CART')} />}
                {contentType === 'CUSTOMER_DATA' && <CustomerDataContent onNext={() => setContentType('OVERVIEW')} onPrev={() => setContentType('AUTH')} />}
                {contentType === 'OVERVIEW' && <OverviewContent onPrev={() => setContentType('CUSTOMER_DATA')} onNext={(result) => setResult(result)} />}
                {contentType === 'FEEDBACK' && <FeedbackContent result={result} />}

                {contentType === 'PAYMENT_LOADING' && <PaymentLoadingContent />}
                {contentType === 'PAYMENT_FEEDBACK' && <PaymentFeedbackContent paymentResult={paymentResult} />}

            </div>

        </NBossCMSPage>
    );
};

function StepList(props) {
    const stepList = [
        { label: getTranslation('cart'), keyList: ['CART'] },
        { label: getTranslation('login'), keyList: ['AUTH'] },
        { label: getTranslation('data'), keyList: ['CUSTOMER_DATA'] },
        { label: getTranslation('overview'), keyList: ['OVERVIEW'] },
        { label: getTranslation('done'), keyList: ['FEEDBACK', 'PAYMENT_FEEDBACK'], highlight: true },
    ];

    const isActive = (key) => {
        let activeIndex = -1;
        let currentIndex = -1;
        for (let i = 0; i < stepList.length; i++) if (stepList[i].keyList.indexOf(props.current) > -1) currentIndex = i;
        for (let i = 0; i < stepList.length; i++) if (stepList[i].keyList.indexOf(key) > -1) activeIndex = i;
        return (props.current === 'FEEDBACK' || props.current === 'PAYMENT_FEEDBACK') ? activeIndex === currentIndex : activeIndex <= currentIndex;
    }

    return (
        <div className='relative'>

            <div className='w-full flex flex-row items-center justify-between'>
                {stepList.map((item, index) => (<StepItem key={item.key} step={index + 1} label={item.label} active={isActive(item.keyList[0])} highlight={item.highlight} onClick={() => props.onContentTypeChanged(item.keyList[0])} />))}
            </div>

            <div className='absolute w-full left-0 top-4 lg:top-1/2 h-[1px] bg-gray-100 -z-10' />

        </div>
    )
}

function StepItem(props) {
    return (
        <button disabled={!props.active} className={'bg-white flex flex-col lg:flex-row items-center gap-2 lg:gap-4 px-2 sm:px-4 first:pl-0 last:pr-0 ' + (props.active ? 'opacity-100' : 'opacity-100')} onClick={() => props.onClick()}>

            {!props.hideIndex && <div className={'w-8 h-8 sm:w-10 sm:h-10 xl:w-14 xl:h-14 rounded-full flex items-center justify-center transition ' + (props.active ? (props.highlight ? 'gold-gradient' : 'black-gradient') : 'bg-gray-100')}>
                <div className='text-white font-slab text-sm xl:text-xl xl:mb-1'>{props.step}</div>
            </div>}

            <div className={'font-cursive text-sm lg:text-lg xl:text-3xl transition ' + (props.active ? 'text-gold' : 'text-gray-100')}>{props.label}</div>

        </button>
    )
}

function CartContent(props) {
    return (
        <div className='flex flex-col gap-8'>

            <OrderCart />

            <ButtonBlock leftLabel={getTranslation('countinue-shopping')} rightLabel={getTranslation('place-order')} onRightClick={() => props.onNext()} />

        </div>
    );
}

function OrderCart(props) {
    return (
        <Cart
            disabled={props.disabled}
            headingEnabled
            headingClassName='heading-small my-4 first:mt-0 last:mb-0 text-black'
            cartItemHeaderClassName='text-xs'
            cartItemColClassName=''
            cartItemColHighlightClassName='text-lg'
            cartDiscountItemClassName='bg-gray-100 text-sm font-normal font-slab'
            cartSummaryItemClassName='text-white text-sm xl:text-lg bg-gold aspect-none py-6 md:py-8 xl:py-16' />
    );
}

function CustomerDataContent(props) {
    const { isLoggedIn } = useAuth();

    const methods = useForm({ mode: 'onChange', defaultValues: {} });
    const { handleSubmit } = methods;

    const [userData, setUserData] = useState(null);
    const [validationData, setValidationData] = useState(null);

    useEffect(() => {
        ShopOrderControl.getCustomer().then(result => { setUserData(result); });
    }, [isLoggedIn]);

    const onSubmit = data => {
        ShopOrderControl.setupCustomer(data).then(result => {
            if (result.successful)
                props.onNext();
            else
                setValidationData(result);
        });
    };

    return (
        <div className='flex flex-col gap-8'>
            <FormProvider {...methods}>

                <Profile
                    markMandatoryFields
                    userData={userData}
                    validationData={validationData}
                    blockClassName='rounded bg-gray-100'
                    gridClassName='grid-cols-1 lg:grid-cols-2'
                    blockGridClassName='grid-cols-1 xl:grid-cols-2'
                    onSuccess={() => props.onNext()} />

                <ButtonBlock leftLabel={getTranslation('back')} rightLabel={getTranslation('continue-to-overview')} onLeftClick={() => props.onPrev()} onRightClick={() => { handleSubmit(onSubmit)() }} />

            </FormProvider>
        </div>
    );
}

function AuthContent(props) {
    const { isLoggedIn, userData, handleLogout } = useAuth();

    return (
        <div className='flex flex-col gap-8'>

            <div className='grid lg:grid-cols-2 xl:grid-cols-3 site-main-gap'>

                {!isLoggedIn ? <>

                    <AuthBlock title={getTranslation('login')} buttonLabel={getTranslation('login')} description={getTranslation('for-those-who-have-already-registered')} onClick={() => EventRegister.emit('POPUP_AUTH', { type: 'LOGIN' })} />

                    <AuthBlock title={getTranslation('registration')} buttonLabel={getTranslation('registration')} description={getTranslation('shop-first-time')} onClick={() => EventRegister.emit('POPUP_AUTH', { type: 'REGISTER' })} />

                    <AuthBlock className='lg:col-span-2 xl:col-span-1' title={getTranslation('shop-as-guest')} buttonLabel={getTranslation('countinue-as-guest')} description={getTranslation('possible-as-guest')} onClick={() => props.onNext()} />

                </> : <>

                    <AuthBlock title={getTranslation('logged-in-user')} buttonLabel={getTranslation('logout')} description={getTranslation('already-logged-in-as').replace('USERNAME', userData.userName)} onClick={() => handleLogout()} />

                    <AuthBlock className='xl:col-span-2' title={getTranslation('profile-data')} buttonLabel={getTranslation('my-profile')} description={getTranslation('keep-profile-up-to-date')} onClick={() => EventRegister.emit('POPUP_PROFILE', null)} />

                </>}

            </div>

            <ButtonBlock leftLabel={getTranslation('back')} rightLabel={getTranslation('continue-to-data')} onLeftClick={() => props.onPrev()} onRightClick={() => props.onNext()} />

        </div>
    );
}

function AuthBlock(props) {
    return (
        <div className={'bg-gray-100 p-8 flex flex-col justify-between ' + (props.className || '')}>

            <div className='flex flex-col gap-4'>
                <div className='heading'>{props.title}</div>
                <div className='leading-7' dangerouslySetInnerHTML={{ __html: props.description }} />
            </div>

            {props.buttonLabel && <button className='button mt-8' onClick={() => props.onClick()}>{props.buttonLabel}</button>}

        </div>
    );
}

function OverviewContent(props) {
    const { isLoggedIn } = useAuth();
    const { updateBasket } = useShop();

    const [userData, setUserData] = useState(null);

    useEffect(() => { ShopOrderControl.getCustomer().then(result => { setUserData(result); }); }, [isLoggedIn]);

    const order = () => { ShopOrderControl.order().then(result => { props.onNext(result); updateBasket(); }); };

    return (
        <div className='flex flex-col gap-8'>

            {userData && <div className='grid lg:grid-cols-2 xl:grid-cols-3 site-main-gap'>

                <OverviewLister className='col-span-1 lg:col-span-2 xl:col-span-1' title={getTranslation('contacts')}>

                    {userData.email && <OverviewListerLine label={getTranslation('email-address')} value={userData.email} />}
                    {userData.phone && <OverviewListerLine label={getTranslation('phone-number')} value={userData.phone} />}

                </OverviewLister>

                <OverviewLister title={getTranslation('billing-data')}>

                    {userData.billName && <OverviewListerLine label={getTranslation('name')} value={userData.billName} />}
                    {userData.billCountry && <OverviewListerLine label={getTranslation('country')} value={userData.billCountry} />}
                    {userData.billState && <OverviewListerLine label={getTranslation('state')} value={userData.billState} />}
                    {userData.billZip && <OverviewListerLine label={getTranslation('zip-code')} value={userData.billZip} />}
                    {userData.billCity && <OverviewListerLine label={getTranslation('city')} value={userData.billCity} />}
                    {userData.billAddress && <OverviewListerLine label={getTranslation('address')} value={userData.billAddress} />}
                    {userData.billTax && <OverviewListerLine label={getTranslation('tax')} value={userData.billTax} />}
                    {userData.billIsCompany && <OverviewListerLine label={getTranslation('tax')} value={userData.billIsCompany ? 'Igen' : 'Nem'} />}

                </OverviewLister>

                <OverviewLister title={getTranslation('shipping-data')}>

                    {userData.shippingName && <OverviewListerLine label={getTranslation('name')} value={userData.shippingName} />}
                    {userData.shippingCountry && <OverviewListerLine label={getTranslation('country')} value={userData.shippingCountry} />}
                    {userData.shippingState && <OverviewListerLine label={getTranslation('state')} value={userData.shippingState} />}
                    {userData.shippingZip && <OverviewListerLine label={getTranslation('zip-code')} value={userData.shippingZip} />}
                    {userData.shippingCity && <OverviewListerLine label={getTranslation('city')} value={userData.shippingCity} />}
                    {userData.shippingAddress && <OverviewListerLine label={getTranslation('address')} value={userData.shippingAddress} />}
                    {userData.shippingComment && <OverviewListerLine label={getTranslation('comment')} value={userData.shippingComment} />}

                </OverviewLister>

            </div>}

            <OrderCart disabled />

            <ButtonBlock leftLabel={getTranslation('back')} rightLabel={getTranslation('place-the-order')} onLeftClick={() => props.onPrev()} onRightClick={() => order()} />

        </div>
    );
}

function OverviewLister(props) {
    return (
        <div className={'bg-gray-100 p-8 flex flex-col gap-8 ' + (props.className || '')}>

            <div className='heading-small'>{props.title}</div>

            <div className='flex flex-col gap-2'>
                {props.children}
            </div>

        </div>
    );
}

function OverviewListerLine(props) {
    return (
        <div className='w-full flex flex-row items-center justify-between tracking-wider'>

            <div className='bg-gray-100 text-xs pr-4 font-slab'>{props.label}</div>
            <div className='flex-1 h-[1px] bg-zinc-300' />
            <div className='bg-gray-100 text-xs font-bold pl-4 text-right'>{props.value ? props.value : '-'}</div>

        </div>
    );
}

function FeedbackContent(props) {
    const [count, setCount] = useState(null);

    useEffect(() => {
        if (count === 0) { redirectToPaymentUrl(); setCount(null); }
        if (!count) return;
        const intervalId = setInterval(() => { setCount(count - 1); }, 1000);
        return () => clearInterval(intervalId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [count]);

    useEffect(() => { setCount(10); }, []);

    const redirectToPaymentUrl = () => {
        if (props.result && props.result.paymentUrl)
            window.open(props.result.paymentUrl, '_self');
    };

    return (
        <div className='flex flex-col gap-8'>

            {(props.result && !props.result.paymentUrl) && <FeedbackBlock className='gold-gradient text-white gap-2' heading={getTranslation('thanks-for-choosing-neroli')} subHeading={getTranslation('successful-order')} />}

            {(props.result && props.result.paymentUrl) && <FeedbackBlock heading={getTranslation('thanks-for-choosing-neroli')} content={getTranslation('payment-countdown').replace('TIME', (count ? (count + ' ' + getTranslation('in-seconds')) : getTranslation('now')))}>
                <a href={props.result.paymentUrl} target='_self' className='button plain dark mt-4'>{getTranslation('continue-to-payment')}</a>
            </FeedbackBlock>}

            <FeedbackBlock>

                <div className='text-lg lg:text-xl font-slab'>{getTranslation('the-order-id')}: <strong>{(props.result && props.result.orderId) ? props.result.orderId : 'N/A'}</strong></div>

                <FeedbackInfoBlock />

            </FeedbackBlock>

            {(props.result && !props.result.paymentUrl) && <MainPageButton />}

        </div>
    );
}

function PaymentLoadingContent(props) {
    return (<FeedbackBlock heading={getTranslation('processing-of-payment')} subHeading={getTranslation('process-takes-a-few-seconds')} />);
}

function PaymentFeedbackContent(props) {
    return (
        <div className='flex flex-col gap-8'>

            <FeedbackBlock className='gold-gradient text-white' heading={props.paymentResult && props.paymentResult.responseMessage} subHeading={props.paymentResult.transactionId ? getTranslation('tansaction-successful') : getTranslation('tansaction-unsuccessful')} />

            <FeedbackBlock>

                <div className='text-lg lg:text-xl font-slab'>{getTranslation('the-tansaction-id')}: <strong>{(props.paymentResult && props.paymentResult.transactionId) ? props.result.orderId : 'N/A'}</strong></div>

                <FeedbackInfoBlock />

            </FeedbackBlock>

            <MainPageButton />

        </div>
    );
}

function MainPageButton(props) {
    return (<Link to='/' className='flex-1 button plain dark'>{getTranslation('back-to-main-page')}</Link>);
}

function FeedbackInfoBlock(props) {
    let _data = {
        phone: getValueFromParameterMap(getWebsite().globalContent, 'phone'),
        email: getValueFromParameterMap(getWebsite().globalContent, 'email'),
    }

    return (
        <>
            <div className='text-sm' dangerouslySetInnerHTML={{ __html: getTranslation('payment-feedback-info') }} />

            <div className='flex flex-col xl:flex-row xl:items-center gap-2'>
                <div className='text-sm'>{getTranslation('payment-feedback-contact')}</div>

                <div className='flex flex-col sm:flex-row gap-2 sm:gap-4 text-sm'>

                    {_data.phone && <DataLine icon={<TfiMobile />} href={'tel:' + _data.phone} label={_data.phone} />}
                    {_data.email && <DataLine icon={<TfiEmail />} href={'mailto:' + _data.email} label={_data.email} />}

                </div>
            </div>
        </>
    );
}

function FeedbackBlock(props) {
    return (
        <div className={'bg-gray-100 gap-4 w-full p-8 lg:p-16 flex flex-col ' + (props.className || '')}>

            {props.subHeading && <div className='font-slab text-sm lg:text-lg'>{props.subHeading}</div>}
            {props.heading && <div className='font-slab text-lg lg:text-3xl'>{props.heading}</div>}
            {props.content && <div className='font-slab text-sm lg:text-lg'>{props.content}</div>}

            {props.children}

        </div>
    );
}

function ButtonBlock(props) {
    return (
        <div className='flex flex-col md:flex-row gap-4'>
            <button className='flex-1 button plain dark' onClick={() => props.onLeftClick()}>{props.leftLabel}</button>
            <button className='flex-1 button plain dark' onClick={() => props.onRightClick()}>{props.rightLabel}</button>
        </div>
    );
}