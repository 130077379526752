import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { motion } from 'framer-motion';

import Menu from 'components/Menu';
import Header from 'components/Header';
import Footer from 'components/Footer';
import CookieConsent from 'components/CookieConsent';

import MainPage from 'pages/MainPage';
import ContentPage from 'pages/ContentPage';
import ContactPage from 'pages/ContactPage';
import ProductListPage from 'pages/ProductListPage';
import ProductPage from 'pages/ProductPage';
import ProductFilterPage from 'pages/ProductFilterPage';
import ShopListPage from 'pages/ShopListPage';
import OrderPage from 'pages/OrderPage';
import GiftCardPage from 'pages/GiftCardPage';
import SearchPage from 'pages/SearchPage';
import Error404Page from 'pages/Error404Page';

import ScrollToTop from 'utils/ScrollToTop';

import CmsFrontendControl from 'services/CmsFrontendControl';
import { setWebsite } from 'utils/WebsiteUtils';

export default function NBossCMSClient(props) {
    const [website, _setWebsite] = useState(null);
    const [routes, setRoutes] = useState(null);

    const pageDictionary = {
        'main-page': { page: <MainPage />, templateProps: {} },
        'shop-list': { page: <ShopListPage />, templateProps: {} },
        'content-page': { page: <ContentPage />, templateProps: {} },
        'productListPage': { page: <ProductListPage />, templateProps: {} },
        'custom-product-list': { page: <ProductListPage />, templateProps: {} },
        'productPage': { page: <ProductPage />, templateProps: {} },
        'parfumvalaszto': { page: <ProductFilterPage />, templateProps: {} },
        'giftCard': { page: <GiftCardPage />, templateProps: {} },
        'contact': { page: <ContactPage />, templateProps: {} },
        'order': { page: <OrderPage />, templateProps: {} },
        'search': { page: <SearchPage />, templateProps: {} },
        
        '404': { page: <Error404Page />, templateProps: {} },
    };

    const pageKey404 = 'egyszeru-oldal';

    const createRoutes = (website) => {
        let _routes = [];

        if (website.mainPage)
            _routes.push({ path: '/', element: React.cloneElement(pageDictionary[website.mainPage.pageTemplate].page, { pageKey: website.mainPage.pageKey, templateProps: pageDictionary[website.mainPage.pageTemplate].templateProps }) });

        for (let key in website.sitemap)
            if (website.sitemap[key].pageTemplate && pageDictionary[website.sitemap[key].pageTemplate])
                _routes.push({ path: key, element: React.cloneElement(pageDictionary[website.sitemap[key].pageTemplate].page, { pageKey: website.sitemap[key].pageKey, templateProps: pageDictionary[website.sitemap[key].pageTemplate].templateProps }) });
            else
                console.log('[Missing template] ' + (website.sitemap[key].pageTemplate ? website.sitemap[key].pageTemplate : 'No template defined.') + ' [for] ' + key);

        _routes.push({ path: '*', element: React.cloneElement(pageDictionary['404'].page, { pageKey: pageKey404 }) });

        return _routes;
    }

    useEffect(() => {
        // const controller = new AbortController();
        // axios.post('http://oszsz.dev.office.creo.hu/oszsz-portal/methods/cmsFrontendControl/getWebsite',
        //     { domainName: props.domainName, url: window.location.pathname, },
        //     { signal: controller.signal, }).then(function (response) {
        //         _setWebsite(response)
        //     });
        // return () => controller.abort();

        if (!website && props.domainName)
            CmsFrontendControl.getWebsite(props.domainName, window.location.pathname).then(result => _setWebsite(result));
    }, [website, props.domainName]);

    useEffect(() => {
        if (website !== null) {
            setWebsite(website);
            setRoutes(createRoutes(website))
            setSiteProperties();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [website]);

    const setSiteProperties = () => {
        // let link = document.querySelector("link[rel~='icon']");
        // if (!link) {
        //     link = document.createElement('link');
        //     link.rel = 'icon';
        //     document.getElementsByTagName('head')[0].appendChild(link);
        // }
        // link.href = getValueFromParameterMap(website.globalContent, 'logo').downloadUrl;

        // document.title = getValueFromParameterMap(website.globalContent, 'name');
    }

    if (website && routes) {
        return (
            <Router>

                <div className='relative bg-white min-h-screen w-full z-10 shadow-xl flex flex-col'>

                    <Header className='order-1 z-40' />

                    <div className='w-full flex site-main-pb order-2'>

                        <div className='w-full site-main-px site-main-grid site-main-gap'>

                            <div className='hidden md:block'>
                                <Menu className='sticky top-24' right scrollIndicator/>
                            </div>

                            <div className='w-full h-max site-content-col-span flex flex-col site-main-gap'>

                                <ScrollToTop />

                                <Routes>{routes.map((route, index) => (<Route key={route.path} exact path={route.path} element={route.element} />))}</Routes>

                            </div>

                        </div>

                    </div>

                </div>

                <Footer className='w-full sticky top-0 bottom-0 left-0 right-0' />

                <CookieConsent />

            </Router >
        );
    } else {
        return (
            <div className='w-full min-h-screen flex flex-col justify-center items-center gap-4'>
                <img className='h-[70px]' alt='logo' src='/assets/images/logo/full-gold-gradient.svg' />
                <p className='uppercase font-slab text-gold text-sm tracking-wider'>Betöltés...</p>
            </div>
        );
    }
}

export function NBossCMSPage(props) {
    useEffect(() => {
        // if (checkPageLanguage(getWebsite().sitemap, window.location.pathname, getWebsite().currentLocale.key))
        CmsFrontendControl.getPage(props.pageprops.pageKey).then(result => { props.pageDataReady ? props.pageDataReady(result) : console.log('No pageDataReady callback defined.') });
        // else
        //     window.location.reload();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.pageprops.pageKey]);

    // const checkPageLanguage = (sitemap, path, localeKey) => {
    //     const hasSitemapItem = sitemap[path] ? true : false;
    //     // console.log('hasSitemapItem: ' + hasSitemapItem);
    //     const hasLocale = hasSitemapItem && (sitemap[path] && sitemap[path].localeList.indexOf(localeKey) > -1);
    //     // console.log('hasLocale: ' + hasLocale);
    //     const isMainPage = path === '/' || path === '';
    //     // console.log('isMainPage: ' + isMainPage);

    //     return (hasLocale || isMainPage || !hasSitemapItem) ? true : false;
    // }

    return (<motion.div
        className={props.className}
        // style={{ originX: 0.5, originY: 0, transformPerspective: 2000 }}
        transition={{ duration: .3 }}
        initial={{ opacity: 0/*, rotateX: 0*/ }}
        animate={{ opacity: 1/*, rotateX: 0*/ }}
        exit={{ opacity: 1/*, rotateX: 0*/ }}>
        {props.children}
    </motion.div>);
}

export const getValueFromParameterMap = (data, key) => {
    if (data && key && data.parameterMap[key])
        return data.parameterMap[key].value;
    return null;
};

export const getNBossEndpoint = () => {
    return `http${process.env.REACT_APP_API_SECURE === 'true' ? `s` : ``}://${process.env.REACT_APP_API_HOST + (typeof process.env.REACT_APP_API_PORT !== 'undefined' ? `:${process.env.REACT_APP_API_PORT}` : ``)}/${process.env.REACT_APP_API_NAME}/methods/`;
}