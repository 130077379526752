import { useState, useEffect } from 'react';

import PmFrontendControl from 'services/PmFrontendControl';

import { getTranslation } from 'utils/DictionaryUtils';

export default function Newsletter(props) {
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);

    const [successful, setSuccessful] = useState(false);

    useEffect(() => {
        setEmailError(false);
    }, [email]);

    useEffect(() => {
        setEmail('');
    }, [successful]);

    const subscribe = () => {
        PmFrontendControl.subscribe(email).then(result => {
            if (result.successful)
                setSuccessful(true);
            else
                setEmailError((result.properties.email) ? result.properties.email[0].message : getTranslation('please-check-data'));
        });
    };

    return (
        <div className={' ' + (props.className || '')}>

            {successful ? <button className='flex flex-col gap-2 bg-gold p-4 text-white' onClick={() => setSuccessful(false)}>

                <div className='text-xs text-left font-slab tracking-wider'>{getTranslation('subscribe-newsletter-success')}</div>
                <div className='text-[10px] text-left' dangerouslySetInnerHTML={{ __html: getTranslation('subscribe-newsletter-success-content') }} />

            </button> : <>

                <div className='flex flex-col'>
                    <input className={'text-sm font-light text-center py-0 px-2 border ' + (emailError ? 'border border-red-800 text-red-800 ' : 'border border-transparent ') + (props.inputClassName || '')} placeholder={getTranslation('email-address')} value={email} onChange={(e) => setEmail(e.target.value)}></input>
                    {emailError && <div className='bg-red-800 p-2 text-white font-slab text-[10px] tracking-wider text-center'>{emailError}</div>}
                </div>

                <button disabled={successful} className={'flex items-center justify-center h-10 bg-gold hover:bg-gold-light text-white text-sm font-slab font-light transition ' + (props.buttonClassName || '')} onClick={() => subscribe()}>{getTranslation('subscribe')}</button>

            </>}

        </div>
    );
}