import { IoMaleFemaleOutline, IoMaleOutline, IoFemaleOutline } from 'react-icons/io5';

export const getGenderIcon = (obj,className) => {
    if (obj) {
        if (obj.key === 'uniszex')
            return <IoMaleFemaleOutline className={className} />
        else if (obj.key === 'ferfi')
            return <IoMaleOutline className={className} />
        else if (obj.key === 'noi')
            return <IoFemaleOutline className={className} />
    }
    return null;
};
