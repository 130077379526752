import { useEffect } from 'react';
import AuthProvider from 'providers/AuthProvider';
import NBossCMSClient from 'cms/NBossCMS';

import globalConfig from 'config/globalConfig'

import nBossConnection from 'services/nBossConnection';
import ShopProvider from 'providers/ShopProvider';

function App() {
	useEffect(() => {
		nBossConnection.setErrorHandler((error) => {
			// if (typeof error.response.status !== 'undefined' && error.response.status === 500) alert('Internal Server Error!');  else
			alert(error);
		});
	});
	return (
		<AuthProvider>
            <ShopProvider>
			    <NBossCMSClient domainName={globalConfig.domainName}/>
            </ShopProvider>
		</AuthProvider>
	);
}

export default App;
